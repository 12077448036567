import { memo, useEffect, useState } from "react";
import "@arc-web/components/dist/components/button/arc-button";
import {
  AppBar,
  Badge,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HelpIcon from "@mui/icons-material/Help";
import InformationIcon from "@mui/icons-material/Info";
import MenuIcon from '@mui/icons-material/Menu';
import ArupLogoRed from "../images/arupLogoRed.svg";
import ApexLogo from "../images/ApexLogo.png";
import UserName from "./UserName";
import { useMsal } from "@azure/msal-react";
import spService from "../services/spService.api";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  AnnouncementDialog,
  AnnouncementDialogTitle,
} from "./AnnouncementDialog";

export const Header = memo(() => {
  // console.log(`[Header render]`);
  const { instance, accounts } = useMsal();
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [openAnnouncement, setOpenAnnouncement] = useState<any>(undefined);
  const isNotMobile = useMediaQuery('(min-width:600px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabsMenuAnchorEl, setTabsMenuAnchorEl] = useState<null | HTMLElement>(null);
  
  const openAnnouncementDialog = (item: any) => {
    setAnchorEl(null);
    setOpenAnnouncement(item);
  };

  const closeAnnouncementDialog = () => {
    if (localStorage.getItem("ApexDebug"))
      console.log(`[Header] closeAnnouncementDialog will close `, openAnnouncement);
    if (openAnnouncement && openAnnouncement.id) {
      localStorage.setItem("ApexPromotedAnnouncement", openAnnouncement.id);
    }
    setOpenAnnouncement(undefined);
  };

  const openTabsMenu = Boolean(tabsMenuAnchorEl);
  const onMenuIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTabsMenuAnchorEl(event.currentTarget);
  };
  const onTabsMenuClose = () => {
    setTabsMenuAnchorEl(null);
  };

  const onNotificationButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const onNotificationMenuClose = () => {
    setAnchorEl(null);
  };

  const getAnnouncements = async () => {
    const activeEnv =
      window.location.hostname.indexOf("apexportal.arup.com") >= 0
        ? "Production"
        : "Test";
    const url = `/items?expand=fields(select=Title,Body,AutoDisplay,DisplayOrder,Active)&$filter=(fields/Active eq '${activeEnv}')&$orderby=fields/DisplayOrder asc`;

    try {
      const res = await spService(url, "GET", instance, accounts);
      if (!(res?.value?.length > 0)) return;

      const updateRelativeUrls = res?.value?.map((i: any) => {

        if (i.fields?.Body) {
          i.fields.Body = replaceRelativeUrls(i.fields?.Body as string);
        }
        
        return i;
      });

      // Sort items
      const sorted = updateRelativeUrls.sort((a: any, b: any) =>
        (b.fields.DisplayOrder || 0) < (a.fields.DisplayOrder || 0) ? -1 : 1
      );
      const debugMode = localStorage.getItem("ApexDebug");
      if (debugMode)
        console.log(`[Header] Announcements:`, sorted);
      setAnnouncements(sorted);

      //#region Display Promoted item (auto display=true) if never read

      // If ApexPromotedAnnouncement <> promoted id, it wasn't read before. Display it
      const autoDisplayItems = sorted.filter(
        (item: { fields: { AutoDisplay: boolean } }) => item.fields.AutoDisplay
      );

      const cachedPromotedItem =
        localStorage.getItem("ApexPromotedAnnouncement") || "";
      if (
        autoDisplayItems?.length > 0 &&
        cachedPromotedItem !== autoDisplayItems[0]["id"]
      ) {
        openAnnouncementDialog(autoDisplayItems[0]);
        if (debugMode)
          console.log(`[Header] Auto Display item displayed.`, autoDisplayItems[0]);
      }
      //#endregion
    } catch (error) {
      console.log(`SP api call failed.`, error);
    }
  };

  useEffect(() => {
    if (isNotMobile) getAnnouncements();

    return () => {
      console.log(`[Header useEffect unmounting]`);
    };
  }, [isNotMobile]);

  const tabButtonSxProps = {
    fontWeight: "normal",
    fontSize: "12px",
    ...(isNotMobile
      ? {
        textTransform: "none",
        borderRadius: "unset",
        height: "56px",
        px: 2,
      } :  {
        height: "48px",
        width: "100%",
        pl: 3,
        justifyContent: "flex-start",
      }),
  };

  const feedbackButton = (
    <Button
      title="Opens Arup Feedback Hub in a new tab"
      aria-label="Click to open Arup Feedback Hub in a new tab"
      component={Link}
      href="https://feedback.arup.com/apex-portal/51/0/all/new-considered-started-planned/1/votes-desc/dateadded-at"
      underline="none"
      target="_blank"
      rel="noopener"
      color="inherit"
      sx={tabButtonSxProps}
      startIcon={<FeedbackIcon />}
    >
      Feedback
    </Button>
  );

  const helpButton = (
    <Button
      title="Opens help page for Apex Portal in a new tab"
      aria-label="Click to open help page for Apex Portal in a new tab"
      component={Link}
      href="https://arup.sharepoint.com/sites/essentials-global-digital-technology/SitePages/Apex-Portal-guidance.aspx"
      underline="none"
      target="_blank"
      rel="noopener"
      color="inherit"
      sx={tabButtonSxProps}
      startIcon={<HelpIcon />}
    >
      Help
    </Button>
  );

  const infoButton = (
    <Button
      title="Opens information page for Apex Portal in a new tab"
      aria-label="Click to open information page for Apex Portal in a new tab"
      component={Link}
      href="https://arup.sharepoint.com/sites/essentials-global-digital-technology/SitePages/Apex-Portal.aspx"
      underline="none"
      target="_blank"
      rel="noopener"
      color="inherit"
      sx={tabButtonSxProps}
      startIcon={<InformationIcon/>} 
    >
      About Apex
    </Button>
  );

  return (
    <AppBar
      position="relative"
      sx={{ backgroundColor: "#fff", zIndex: 2, overflow: "auto" }}
    >
      <Toolbar
        variant="dense"
        sx={{
          minHeight: "56px",
          color: "#424242",
          // gap: "8px"
        }}
      >
        <div style={{ flexGrow: 1, color: "#353535", flexShrink: 0 }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Apex logo"
            href="/"
            sx={{
              flexGrow: 1,
              color: "#353535",
              textTransform: "none",
              borderRadius: "unset",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {isNotMobile && (
              <img
                src={ApexLogo}
                alt="Apex Logo"
                style={{
                  height: 20,
                  paddingRight: 10,
                }}
              />
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Apex Portal
            </Typography>
          </IconButton>
        </div>

        {isNotMobile ? (
          <>
            <Divider orientation="vertical" flexItem />
            {feedbackButton}
            <Divider orientation="vertical" flexItem />
            {helpButton}
            <Divider orientation="vertical" flexItem />
            {infoButton}
          </>
        ) : (
          <>
            <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-controls={openTabsMenu ? "tabs-menu" : undefined}
              aria-expanded={openTabsMenu ? "true" : undefined}
              sx={{ mr: 2 }}
              onClick={onMenuIconClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="tabs-menu"
              anchorEl={tabsMenuAnchorEl}
              open={openTabsMenu}
              onClose={onTabsMenuClose}
              MenuListProps={{
                "aria-labelledby": "tabs-button",
              }}
              PaperProps={{
                sx: {
                  width: "200px",
                  "& .MuiList-root ": {
                    py: 0,
                  },
                },
              }}
            >
              {feedbackButton}
              <Divider />
              {helpButton}
              <Divider />
              {infoButton}
            </Menu>
          </>
        )}

        {isNotMobile && announcements.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />
            <Button
              disableFocusRipple={true}
              aria-label="announcements"
              color="inherit"
              sx={{
                height: "56px",
                lineHeight: "56px",
                fontWeight: "normal",
                minWidth: 0,
                paddingX: 1,
              }}
              onClick={onNotificationButtonClick}
            >
              <Badge
                badgeContent={
                  announcements.length > 0 ? announcements.length : undefined
                }
                color="info"
                variant="dot"
                invisible={true}
              >
                <NotificationsIcon color="inherit" />
              </Badge>
            </Button>
          </>
        )}
        {isNotMobile && announcements.length > 0 && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onNotificationMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ maxWidth: "80vw" }}
          >
            {announcements.map((a) => (
              <MenuItem
                key={a.fields.Title}
                onClick={() => openAnnouncementDialog(a)}
              >
                <Typography variant="inherit" noWrap>
                  {a.fields.Title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
        {isNotMobile && announcements.length > 0 && openAnnouncement && (
          <AnnouncementDialog
            onClose={closeAnnouncementDialog}
            aria-labelledby="customized-dialog-title"
            open={openAnnouncement ? true : false}
          >
            {openAnnouncement && openAnnouncement.fields.Title && (
              <AnnouncementDialogTitle
                id="customized-dialog-title"
                onClose={closeAnnouncementDialog}
              >
                {openAnnouncement.fields.Title}
              </AnnouncementDialogTitle>
            )}
            <DialogContent dividers>
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: openAnnouncement.fields.Body,
                }}
              />
            </DialogContent>
            <DialogActions>
              <arc-button
                type="outlined"
                color="secondary"
                size="medium"
                onClick={closeAnnouncementDialog}
                style={{ color: "cyan" }}
              >
                Close
              </arc-button>
            </DialogActions>
          </AnnouncementDialog>
        )}

        {isNotMobile && (
          <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
        )}
        <UserName showName={isNotMobile} />

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <img
          src={ArupLogoRed}
          alt="Arup logo"
          style={{
            height: 20,
          }}
        />
      </Toolbar>
    </AppBar>
  );
});

function replaceRelativeUrls(body: string) {
  const hostName = "https://arup.sharepoint.com";
  const result: string = body
    .replaceAll(/href="\/sites\//gi, `href="${hostName}/sites/`)
    .replaceAll(/href="\/teams\//gi, `href="${hostName}/teams/`)
    .replaceAll(/href='\/sites\//gi, `href='${hostName}/sites/`)
    .replaceAll(/href='\/sites\//gi, `href='${hostName}/sites/`);

  return result;
}

