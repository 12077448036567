import { Box, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridToolbar,
  GridRenderCellParams,
  GridValueFormatterParams,
  getGridSingleSelectOperators,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { ProjectsData } from "../types/ProjectsData";
import { NavLink } from "react-router-dom";
import {
  addIppIcons,
  addPadLockIfNoAccess,
  appendPercentSymbol,
  monthNames,
  numberFormatter,
} from "../utils/dataGridHelpers";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  columnVisibilityModelState,
  favouritesErrorMessageState,
  favouritesState,
  reportReadyState,
} from "../State";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { FavoriteButton } from "../common/FavoriteButton";
import { CustomErrorOverlay } from "../common/DataGridOverlays";
import { CustomNoRowsOverlay } from "../common/DataGridOverlays";
import SubMenu from "../common/SubMenu";
import { useMemo } from "react";
import { DataGridCard } from "./DataGridCard";

export default function Favourites(props: { isNarrowWidth?: boolean }) {
const [columnVisibilityModel, setColumnVisibilityModel] =
  useRecoilState<GridColumnVisibilityModel>(columnVisibilityModelState);  
  const favourites = useRecoilValue<ProjectsData[]>(favouritesState);
  const reportReady = useRecoilValue<boolean>(reportReadyState);
  const favouritesErrorMessage = useRecoilValue<string>(
    favouritesErrorMessageState
  );
  const columns = useMemo(()=>getColumns(reportReady),[reportReady]);
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <DataGrid
        disableVirtualization={props.isNarrowWidth ? true : false}
        headerHeight={props.isNarrowWidth ? 0 : 56}
        autoPageSize={props.isNarrowWidth ? true : false}
        error={favouritesErrorMessage ? true : undefined}
        rows={favourites}
        columns={columns}
        components={{
          Row: props.isNarrowWidth ? DataGridCard : undefined,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () =>
            CustomNoRowsOverlay(
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  This table will be populated when you mark a project/job as a
                  'favourite'. <StarIcon />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  To mark a project/job as a favourite, click on an open star.
                  <StarBorderIcon />
                </div>
              </>
            ),
          Toolbar: GridToolbar,
          ErrorOverlay: () =>
            CustomErrorOverlay(
              "Please reload the page or try again later. If the issues continues, please raise a ServiceNow incident for support.",
              favouritesErrorMessage
            ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, placeholder: "Quick filter" },
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
          },
        }}
        // loading={loading}
        disableSelectionOnClick={true}
        disableDensitySelector
        getRowHeight={() => "auto"}
        sx={{
          fontFamily: "Arial",
          fontSize: "14px",
          color: "#353535",
          // '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "6px",
            "&:focus": {
              outline: "none",
            },
          },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer": {
            height: "40px",
          },
          // '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButton-root": {
            fontFamily: "Arial",
            fontSize: "14px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
            {
              fontWeight: "bold",
            },
          // "&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiIconButton-root" :
          // {
          //   color: "red"
          // },
          "&.MuiDataGrid-root .favourite--cell": {
            paddingLeft: "2px",
            paddingRight: "2px",
          },
          "&.MuiDataGrid-root .number--cell": {
            justifyContent: "space-between",
          },
        }}
      />
    </Box>
  );
}

function getColumns(reportReady: boolean): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: "fav",
      cellClassName: "favourite--cell",
      headerName: "Favourite",
      renderHeader: () => "",
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      minWidth: 15,
      width: 32,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <FavoriteButton
          isFavorite={params.row.fav}
          project={params.row.id}
          onStatusChange={() => console.log("onStatusChanged")}
          tabIndexValue={params.hasFocus ? 0 : -1}
          iconTabIndexValue={-1}
        />
      ),
    },
    {
      field: "id",
      cellClassName: "number--cell",
      headerName: "Number",
      headerAlign: "center",
      minWidth: 124,
      align: "center",
      renderCell: (params: GridRenderCellParams<string>) =>
        reportReady ? (
          <NavLink
            to={`/project/${params.value}`}
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params.value}
          </NavLink>
        ) : (
          <>
            <a href={`/project/${params.value}`}>{params.value}</a>
            <SubMenu
              projectNumber={params.value || ""}
              apUrl={
                (params.row.actualJob === 1 || params.row.actualJob === "Billable") && params.row.apUrl
                  ? params.row.apUrl
                  : ""
              }
              oppGuid={params.row.oppGuid || ""}
              startDt={params.row.startDt}
            />
          </>
        ),
      hideable: false,
    },
    {
      field: "n",
      headerName: "Name",
      minWidth: 180,
      flex: 1.5,
      hideable: false,
    },
    {
      field: "pd",
      headerName: "Project director",
      minWidth: 150,
      //hideable: false,
    },
    {
      field: "pm",
      headerName: "Project manager",
      minWidth: 150,
      //hideable: false,
    },
    {
      field: "cm",
      headerName: "Commercial manager",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "fpc",
      headerName: "FPC",
      // flex: 1,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "pp",
      headerName: "Profit %",
      renderCell: addPadLockIfNoAccess,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "lastInvDt",
      headerName: "Last invoice date",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "fuPFF",
      headerName: "Last PFF update",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "wip",
      headerName: "Work in progress",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "cf",
      headerName: "Cashflow",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "accCentre",
      headerName: "Accounting centre",
      minWidth: 150,
      flex: 1.25,
    },
    { field: "client", headerName: "Client", minWidth: 120, flex: 1 },
    {
      field: "grp",
      headerName: "Group",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "ippC",
      headerName: "Contract status",
      minWidth: 150,
      renderCell: addIppIcons,
      type: "singleSelect",
      valueOptions: ["Ok", "Warning", "Critical"],
      filterOperators: [
        ...getGridSingleSelectOperators(),
        {
          value: "isEmpty",
          getApplyFilterFn: () => {
            return ({ value }): boolean => {
              return value === "" || value == null;
            };
          },
          requiresFilterValue: false,
        },
      ],
    },
    // customisable columns
    {
      field: "debt",
      headerName: "Total debts",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    // { field: "updatedDate"}
  ];
  return columns;
}
