import * as React from "react";
import { styled, SxProps, Theme } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { NavLink, NavLinkProps } from "react-router-dom";
import { ReportPage } from "../config/appConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faSquarePen,
  faArrowRight,
  faAddressCard,
  faMaximize,
  faDollarSign,
  faUsersGear,
  faBriefcase,
  faArrowsRotate,
  faFileContract,
  faFileInvoiceDollar,
  faListCheck,
  faPeopleArrows,
  faScaleBalanced,
  faClipboardCheck,
  faGraduationCap,
  faHandHolding,
} from "@fortawesome/free-solid-svg-icons";
import ChartLine from "../icons/ChartLine";
import SkillsNetwork from "../icons/SkillsNetwork";
import NavItemSubMenu from "./NavItemSubMenu";
import { useRecoilState } from "recoil";
import { sidebarExpandedState } from "../State";
import { getRegionMatchedLink } from "../utils/helpers";
import { HtmlTooltip } from "../common/HtmlTooltip";
import TagManager from 'react-gtm-module';
import posthog from 'posthog-js'

const StyledList = styled(List)<{ component?: React.ElementType }>({
  "& .MuiListItemIcon-root": {
    minWidth: 0,

    svg: {
      width: 16,
    },
  },
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, .03)",
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  // theme.palette.mode === "dark"
  //   ? "rgba(255, 255, 255, .05)"
  //   : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: 0,
  height: "44px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0 0 0 8px",
    alignItems: "center",
    gap: "8px",

    ".MuiSvgIcon-root, .fa-link": {
      boxSizing: "border-box",
      background: "#767676",
      border: "2px solid #DBDBDB",
      borderRadius: "4px",
      width: "44px",
      height: "40px",
      color: "rgba(219, 219, 219, 1)",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "auto",
      padding: "8px 0",
    },

    ".MuiTypography-root": {
      fontFamily: "'Arial'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
      height: "24px",
      letterSpacing: "0.4px",
      color: "#575757",
    },
  },
}));

interface ListItemLinkProps {
  onLinkClick?: (link: string) => void;
  icon?: React.ReactElement;
  primary: string;
  to: string;
  disableGutters?: boolean;
  sx?: SxProps<Theme> | undefined;
  style?: React.CSSProperties | undefined;
  primaryTextLineHeight?: string | undefined;
  iconSx?: SxProps<Theme> | undefined;
  disableLink?: boolean;
  projectRelativeUrl?: string;
  vpnIcon?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, NavLinkProps>(function Link(
  itemProps,
  ref
) {
  return <NavLink end ref={ref} {...itemProps} role={undefined} />;
});

// Used for navigation to project pages (within Apex portal)
function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, disableGutters, sx, iconSx, disableLink, projectRelativeUrl, onLinkClick } = props;

  const handleClick = (link: string) => {
    if (onLinkClick) {
      onLinkClick(link);
    }
    addTags(link);
  };
  return (
    <ListItemButton
      onClick={() => handleClick(primary)}
      disabled={disableLink}
      component={Link}
      to={to}
      disableRipple
      disableGutters={disableGutters}
      sx={[
        { color: "#575757", gap: "8px", pr: 0 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon ? (
        <ListItemIcon sx={{ height: 20, width: 20 }}>{icon}</ListItemIcon>
      ) : (
        <DescriptionIcon sx={iconSx} />
      )}
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          fontFamily: "'Arial'",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "20px",
          letterSpacing: "0.65px",
          color: "#575757",
          flex: "1 1 100%",
        }}
      />
      <NavItemSubMenu projectRelativeUrl={projectRelativeUrl} />
    </ListItemButton>
  );
}

// Used for Tools and Links items which are external links to other applications
function ListItemAnchorLink(props: ListItemLinkProps) {
  const { icon, primary, to, disableGutters, style, primaryTextLineHeight, vpnIcon, onLinkClick } =
    props;
  const handleClick = (link: string) => {
    if (onLinkClick) {
      onLinkClick(link);
    }
    addTags(link);
  };

  return (
    <a
      onClick={() => handleClick(primary)}
      href={to}
      target="_blank"
      style={{
        cursor: "pointer",
        WebkitUserSelect: "none",
        userSelect: "none",
        verticalAlign: "middle",
        MozAppearance: "none",
        WebkitAppearance: "none",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        textDecoration: "none",
        width: "100%",
        boxSizing: "border-box",
        textAlign: "left",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        color: "#575757",
      }}
    >
      <HtmlTooltip
        disableFocusListener
        disableInteractive
        title={
          vpnIcon && (
            <Typography
              fontSize="12px"
              variant="body1"
              display="block"
              color="inherit"
              gutterBottom
            >
              {`Navigate to ${primary}`}. This tool requires a connection to
              Arup’s VPN or network
            </Typography>
          )
        }
      >
        <ListItemButton
          disableRipple
          disableGutters
          sx={{ color: "#575757", gap: "8px", pl: 3 }}
          style={style}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={primary}
            primaryTypographyProps={{
              fontFamily: "'Arial'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: `${primaryTextLineHeight || "20px"} `,
              letterSpacing: "0.65px",
              color: "#575757",
            }}
            sx={{
              flex: "0 1 auto",
              marginTop: "unset",
              marginBottom: "unset",
              paddingBottom: "2px",
              paddingTop: "2px",
            }}
          />
          {vpnIcon && (
            <ListItemIcon>
              <VpnLockIcon />
            </ListItemIcon>
          )}
        </ListItemButton>
      </HtmlTooltip>
    </a>
  );
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: "0",
  borderTop: "1px solid rgba(0, 0, 0, .125)",

  "& .MuiListItemButton-root, & .MuiListItemButton-root:hover": {
    paddingTop: "0px",
    paddingBottom: "0px",
    minHeight: "34px"
  },
  "& .MuiSvgIcon-root": {
    flexShrink: "1",
  },
}));

const addTags = (eventTitle: string) => {
  //Add Posthog capture event
  posthog.capture('Sidebar_ClickEvent', {
    page_title: eventTitle
  });
  const tagManagerArgs = {
    gtmId: "GTM-KPF2RLX",
  };
  TagManager.initialize(tagManagerArgs);
  TagManager.dataLayer({
    dataLayer: {
      event: 'Sidebar_ClickEvent', // event name declared during initialization
      page_title: eventTitle
    }
  });
}

export default function Sidebar(props: { project: string; expanded: boolean; disabled?: boolean; region: string; onLinkClick?: (link: string) => void; }): JSX.Element {
  const { project, disabled, expanded, onLinkClick } = props;
  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [open3, setOpen3] = React.useState(true);
  const [openProjectTools, setOpenProjectTools] = React.useState(true);
  const [openAdvice, setOpenAdvice] = React.useState(true);
  const [openAdviceGuidance, setOpenAdviceGuidance] = React.useState(true);
  const [openAdviceLearning, setOpenAdviceLearning] = React.useState(true);
  const [sectionExpanded, setSectionExpanded] = React.useState<string | false>("panel2");
  const [sidebarExpanded, setSidebarExpanded] = useRecoilState<boolean>(sidebarExpandedState);
  const HSEQDate  = new Date("2023-10-06T00:00:00Z");
  const [launchPadURL, setLaunchPadURL] = React.useState("https://launchpad.arup.com/projects");
  let isLaunchpad = false;
  const startDt = localStorage.getItem("StartDate");
  if (startDt !== undefined && startDt !== null) {
    const startDate = new Date((startDt!== 'undefined' && startDt !== null) ? startDt : "");
    if (startDate >= HSEQDate) {
      isLaunchpad = true;
    }
  }

  const handleGroupClick = (group: string) => {
    switch (group) {
      case "1":
        setOpen(!open);
        break;
      case "2":
        setOpen2(!open2);
        break;
      case "3":
        setOpen3(!open3);
        break;
      case "project-tools":
        setOpenProjectTools(!openProjectTools);
        break;
      case "advice":
        setOpenAdvice(!openAdvice);
        break;
      case "advice-guidance":
        setOpenAdviceGuidance(!openAdviceGuidance);
        break;
      case "advice-learning":
        setOpenAdviceLearning(!openAdviceLearning);
        break;
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setSectionExpanded(newExpanded ? panel : false);

      if (!sidebarExpanded) {
        setSidebarExpanded(true);
      }

    };

  React.useLayoutEffect(() => {
    if (project) {
      setLaunchPadURL("https://launchpad.arup.com/project/"+project+"/summary");
      setSectionExpanded("panel1");
    }else{
      setLaunchPadURL("https://launchpad.arup.com/projects");
    }
  }, [project]);

  const amsLink = getRegionMatchedLink(props.region, "AMS");
  const legalLink = getRegionMatchedLink(props.region, "Legal");
  const financeLink = getRegionMatchedLink(props.region, "Finance");

  return (
    <>
      <Accordion
        expanded={sectionExpanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            ...(!sidebarExpanded && {
              "& .MuiAccordionSummary-content": {
                margin: "0 4px !Important",
              },
            }),
          }}
        >
          <DashboardIcon titleAccess="Navigation" />
          {expanded && <Typography>Navigation</Typography>}
        </AccordionSummary>
        {expanded && (
          <AccordionDetails>
            {project && (
              <StyledList
                component="nav"
                aria-labelledby="nested-list-subheader"
                disablePadding
              >
                <ListItemLink
                  onLinkClick={onLinkClick}
                  to={`/project/${project}`}
                  primary={`Project summary for ${project}`}
                  sx={{ pl: 1 }}
                  iconSx={{ marginRight: "2px" }}
                  disableLink={disabled}
                  projectRelativeUrl=""
                />

                <ListItemLink
                  onLinkClick={onLinkClick}
                  to={`/project/${project}/details`}
                  primary={ReportPage.Details.displayName}
                  sx={{ pl: 3 }}
                  iconSx={{ marginRight: "2px" }}
                  disableLink={disabled}
                  projectRelativeUrl="details"
                />

                {/* Finance, income, costs and profit */}
                <ListItemButton
                  onClick={() => handleGroupClick("1")}
                  sx={{ gap: "8px", pl: 3 }}
                  disableRipple
                >
                  {open ? (
                    <ArrowDropDownIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  ) : (
                    <ArrowRightIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  )}
                  <ListItemText
                    primary="Finance, income, costs and profit"
                    primaryTypographyProps={{
                      fontFamily: "'Arial'",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "20px",
                      letterSpacing: "0.65px",
                      color: "#575757",
                    }}
                    sx={{
                      opacity: disabled ? "0.38" : "unset",
                    }}
                  />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/financial-report`}
                      primary="Project financial report"
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="financial-report"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/job-financial-report`}
                      primary={ReportPage.JobFinancialReport.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="job-financial-report"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/expenses`}
                      primary={ReportPage.Expenses.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="expenses"
                    />
                    {/* <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/compare-expenses`}
                      primary={ReportPage.CompareExpenses.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="compare-expenses"
                    /> */}
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/invoices`}
                      primary={ReportPage.Invoices.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="invoices"
                    />
                  </List>
                </Collapse>

                {/* ===Staffing, resourcing and responsibilities=== */}
                <ListItemButton
                  onClick={() => handleGroupClick("2")}
                  sx={{ gap: "8px", pl: 3 }}
                  disableRipple
                >
                  {open2 ? (
                    <ArrowDropDownIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  ) : (
                    <ArrowRightIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  )}
                  <ListItemText
                    primary="Staffing, resourcing and responsibilities"
                    primaryTypographyProps={{
                      fontFamily: "'Arial'",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "20px",
                      letterSpacing: "0.65px",
                      color: "#575757",
                    }}
                    sx={{
                      opacity: disabled ? "0.38" : "unset",
                    }}
                  />
                </ListItemButton>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/timesheets`}
                      primary={ReportPage.Timesheets.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="timesheets"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/staff-resourcing`}
                      primary={ReportPage.StaffResourcing.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="staff-resourcing"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/hours-and-costs`}
                      primary={ReportPage.HoursAndCosts.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="hours-and-costs"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/site-visit-training-compliance`}
                      primary={
                        ReportPage.SiteVisitTrainingCompliance.displayName
                      }
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="site-visit-training-compliance"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/unexpected-time-bookings`}
                      primary={ReportPage.UnexpectedTimeBookings.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="unexpected-time-bookings"
                    />
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/project-team-members`}
                      primary={ReportPage.ProjectTeamMembers.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="project-team-members"
                    />
                  </List>
                </Collapse>

                {/* Scope, contract, changes and variations */}
                <ListItemButton
                  onClick={() => handleGroupClick("3")}
                  sx={{ gap: "8px", pl: 3 }}
                  disableRipple
                >
                  {open3 ? (
                    <ArrowDropDownIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  ) : (
                    <ArrowRightIcon
                      sx={{ height: 20, width: 20 }}
                      color={disabled ? "disabled" : undefined}
                    />
                  )}
                  <ListItemText
                    primary="Scope, contract, changes and variations"
                    primaryTypographyProps={{
                      fontFamily: "'Arial'",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "20px",
                      letterSpacing: "0.65px",
                      color: "#575757",
                    }}
                    sx={{
                      opacity: disabled ? "0.38" : "unset",
                    }}
                  />
                </ListItemButton>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/opportunities`}
                      primary={ReportPage.Opportunities.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="opportunities"
                    />
                  </List>
                </Collapse>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemLink
                      onLinkClick={onLinkClick}
                      to={`/project/${project}/key-dates`}
                      primary={ReportPage.KeyDates.displayName}
                      sx={{ pl: 5 }}
                      disableLink={disabled}
                      projectRelativeUrl="key-dates"
                    />
                  </List>
                </Collapse>
              </StyledList>
            )}
            {!project && (
              <StyledList
                component="nav"
                aria-labelledby="nested-list-subheader"
                disablePadding
                sx={{
                  color: "#BEBEBE",
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "28px",
                  height: "28px",
                  letterSpacing: "0.65px",
                  pl: 1,
                }}
              >
                No project selected
              </StyledList>
            )}
          </AccordionDetails>
        )}
      </Accordion>

      <Accordion
        expanded={sectionExpanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
          boxShadow: "inset 2px 2px 2px rgb(0 0 0 / 15%)",
        }}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          sx={{
            ...(!sidebarExpanded && {
              "& .MuiAccordionSummary-content": {
                margin: "0 4px !Important",
              },
            }),
          }}
        >
          <FontAwesomeIcon icon={faLink} title="Tools and links" />
          {expanded && <Typography>Tools and links</Typography>}
        </AccordionSummary>
        {expanded && (
          <AccordionDetails>
            <StyledList
              component="nav"
              aria-labelledby="nested-list-subheader"
              disablePadding
            >
              {/* Project tools */}
              <ListItemButton
                onClick={() => handleGroupClick("project-tools")}
                sx={{ gap: "8px", pl: 1 }}
                disableRipple
              >
                {openProjectTools ? (
                  <ArrowDropDownIcon sx={{ height: 20, width: 20 }} />
                ) : (
                  <ArrowRightIcon sx={{ height: 20, width: 20 }} />
                )}
                <ListItemText
                  primary="Project tools"
                  primaryTypographyProps={{
                    fontFamily: "'Arial'",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "20px",
                    letterSpacing: "0.65px",
                    color: "#575757",
                  }}
                />
              </ListItemButton>
              <Collapse in={openProjectTools} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    to="https://projects.intranet.arup.com"
                    primary="Arup Projects"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faSquarePen} />}
                    to="http://concur.cloud.arup.com"
                    primary="Concur"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    to="https://dip.arup.com"
                    primary="DIP"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    to="https://digitalmaturitymeasure.arup.com"
                    primary="DMM"
                    vpnIcon={true}
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faAddressCard} />}
                    to="https://arupcrm.crm11.dynamics.com"
                    primary="Dynamics CRM"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faMaximize} />}
                    to="https://people.arup.com/expertise_dashboard"
                    primary="Expertise and Skills"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    to="https://feeportal.arup.com/ArupFeePortal"
                    primary="Fee Portal"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faUsersGear} />}
                    to="https://pat.arup.com"
                    primary="GRM / PaT"
                  />
                  {props.region === "UKIMEA Region" && (isLaunchpad || !project) && <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faBriefcase} />}
                    to={launchPadURL}
                    primary="Launchpad"
                    vpnIcon={true}
                  />}
                  {(!isLaunchpad || !project) && <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faBriefcase} />}
                    to="https://ipp.arup.com"
                    primary="IPP"
                    vpnIcon={true}
                  />}
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<ChartLine fill="rgba(0, 0, 0, 0.54)" />}
                    to="http://ovaview.arup.com"
                    primary="Ovaview"
                    vpnIcon={true}
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    to="https://pff.arup.com"
                    primary="PFF"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faArrowsRotate} />}
                    to="http://ca.amx.arup.com/Secure/Host.aspx"
                    primary="PM Toolkit"
                    vpnIcon={true}
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faFileContract} />}
                    to="https://project-finder.arup.com"
                    primary="Project Finder"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faHandHolding} />}
                    to="https://arup.sharepoint.com/sites/skillshare"
                    primary="Skillshare"
                  />
                  <ListItemAnchorLink
                    onLinkClick={onLinkClick}
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    to="https://tracksmart.arup.com"
                    primary="TrackSmart"
                  />
                </List>
              </Collapse>

              {/* Advice */}
              <ListItemButton
                onClick={() => handleGroupClick("advice")}
                sx={{ gap: "8px", pl: 1 }}
                disableRipple
              >
                {openAdvice ? (
                  <ArrowDropDownIcon sx={{ height: 20, width: 20 }} />
                ) : (
                  <ArrowRightIcon sx={{ height: 20, width: 20 }} />
                )}
                <ListItemText
                  primary="Advice"
                  primaryTypographyProps={{
                    fontFamily: "'Arial'",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "20px",
                    letterSpacing: "0.65px",
                    color: "#575757",
                  }}
                />
              </ListItemButton>
              <Collapse in={openAdvice} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Collapse
                    in={openAdviceGuidance}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faListCheck} />}
                        to="https://arup.sharepoint.com/sites/essentials-global-ams"
                        primary="AMS - Global"
                      />
                      {props.region && amsLink && (
                        <ListItemAnchorLink
                          onLinkClick={onLinkClick}
                          icon={<FontAwesomeIcon icon={faListCheck} />}
                          to={amsLink.url}
                          primary={amsLink.label}
                        />
                      )}
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faPeopleArrows} />}
                        to="https://arup.sharepoint.com/sites/essentials-global-clients-and-business"
                        primary="Client and business hub"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                        to="https://moodle.arup.com/local/wa_learning_path/index.php?c=learning_path&id=70&a=view"
                        primary="Commercial learning"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<SkillsNetwork fill="rgba(0, 0, 0, 0.54)" />}
                        to="https://arup.sharepoint.com/sites/commercial/SitePages/Commercial.aspx"
                        primary="Commercial skills network"
                        primaryTextLineHeight="18px"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                        to="https://moodle.arup.com/local/wa_learning_path/index.php?c=learning_path&id=78&a=view"
                        primary="Design manager and director learning"
                        primaryTextLineHeight="18px"
                      />
                      {props.region && financeLink && (
                        <ListItemAnchorLink
                          onLinkClick={onLinkClick}
                          icon={<FontAwesomeIcon icon={faArrowRight} />}
                          to={financeLink.url}
                          primary={financeLink.label}
                        />)}
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faArrowRight} />}
                        to="https://arup.sharepoint.com/sites/essentials-global-finance/SitePages/Glossary%20of%20Financial%20Terms.aspx"
                        primary="Finance definitions"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faScaleBalanced} />}
                        to="https://arup.sharepoint.com/sites/essentials-global-legal"
                        primary="Legal - Global"
                      />
                      {props.region && legalLink && (
                        <ListItemAnchorLink
                          onLinkClick={onLinkClick}
                          icon={<FontAwesomeIcon icon={faScaleBalanced} />}
                          to={legalLink.url}
                          primary={legalLink.label}
                        />
                      )}
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faGraduationCap} />}
                        to="https://moodle.arup.com/local/wa_learning_path/index.php?c=learning_path&id=71&a=matrix"
                        primary="Programme and project management learning"
                        primaryTextLineHeight="18px"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<SkillsNetwork fill="rgba(0, 0, 0, 0.54)" />}
                        to="https://arup.sharepoint.com/sites/ppm/SitePages/Programme-and-Project-Management-Skills-Network.aspx"
                        primary="Programme and project management skills network"
                        primaryTextLineHeight="18px"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faArrowRight} />}
                        to="https://arup.sharepoint.com/:b:/r/sites/essentials-global-legal/Shared Documents/Improving Project Performance.pdf"
                        primary="Improving Project Performance cards"
                      />
                      <ListItemAnchorLink
                        onLinkClick={onLinkClick}
                        icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                        to="https://arup.sharepoint.com/sites/essentials-global-arup-university/SitePages/Online-Standards-Codes-Regulatory-Documents-and-Technical-Resources.aspx"
                        primary="Technical standards"
                      />
                    </List>
                  </Collapse>
                </List>
              </Collapse>
            </StyledList>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
}
