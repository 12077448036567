import { Link } from "react-router-dom";
import { DocumentHead } from "../common/DocumentHead";

const PageNotFound = () => {
  return (
    <>
      <DocumentHead title="Apex Portal : Page not found" projectNumber="" subPageTitle=""/>
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 56px)",
      }}
    >
      <h2 style={{ color: "red" }}>Page doesn't exist!</h2>
      <ul>
        <li>
          <Link to="/">Go to the home page to see projects</Link>
        </li>
      </ul>
    </div>
    </>
  );
}

export default PageNotFound;