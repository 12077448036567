import { AccountInfo, IPublicClientApplication, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { msalConfig, siteRead } from '../config/authConfig';

export const PCA = new PublicClientApplication(msalConfig);

export const AquireAccessToken = async (pca: PublicClientApplication, scopes: string[]) => {
    const activeAccount = pca.getActiveAccount();
    const accounts = pca.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {

    }
    const request: SilentRequest = {        
        scopes: scopes,
        account: activeAccount || accounts[0]
    };
    const authResult = await pca.acquireTokenSilent(request);
    return authResult.accessToken;
}

export const AuthToken = async (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
	const token = await instance.acquireTokenSilent({
		...siteRead,
		account: accounts[0],
	});
	let bearer = "Bearer " + token.accessToken;
	return bearer;
};
