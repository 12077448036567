import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactThemeProvider } from "./common/reactTheme";
import { MsalProvider } from "@azure/msal-react";
import { RecoilRoot } from "recoil";
import { HelmetProvider } from "react-helmet-async";

import "@arc-web/components/dist/themes/index.css";
import "@arc-web/components/dist/themes/light.css";
import { setBasePath } from "@arc-web/components/dist/utilities/base-path";
import {
  QueryClient,
  // QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { postHogKey } from "./config/appConfig";
setBasePath("/");

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(accounts[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  // console.log(`[msalInstance.addEventCallback]event=`,event);
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// const queryClient = new QueryClient();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },    
});
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

posthog.init(postHogKey, {
  api_host: 'https://eu.posthog.com',
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <MsalProvider instance={msalInstance}>
      <Router>
        <ReactThemeProvider>
          <HelmetProvider>
            <RecoilRoot>
              {/* <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider> */}
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister: localStoragePersister }}
              >
                <PostHogProvider client={posthog}>
                <App />
                </PostHogProvider>
                { <ReactQueryDevtools initialIsOpen={false} /> }
              </PersistQueryClientProvider>
            </RecoilRoot>
          </HelmetProvider>
        </ReactThemeProvider>
      </Router>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
