import * as React from "react";
import {
  Avatar,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridToolbar,
  GridRenderCellParams,
  GridValueFormatterParams,
  getGridSingleSelectOperators,
  GridColumnVisibilityModel
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo } from "react";
import { ProjectsData } from "../types/ProjectsData";
import { NavLink } from "react-router-dom";
import {
  addIppIcons,
  addPadLockIfNoAccess,
  appendPercentSymbol,
  monthNames,
  numberFormatter,
} from "../utils/dataGridHelpers";
import { DataGridCard } from "./DataGridCard";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  columnVisibilityModelState,
  userPhotoState,
  reportReadyState,
  yourProjectsErrorMessageState,
  yourProjectsState,
  yourProjectsWithFavState,
} from "../State";
import { FavoriteButton } from "../common/FavoriteButton";
import { CustomErrorOverlay, CustomNoRowsOverlay } from "../common/DataGridOverlays";
import SubMenu from "../common/SubMenu";
import { useAccount, useMsal } from "@azure/msal-react";
import NameAvatar from "./NameAvatar";

export default function YourProjects(props: { isNarrowWidth?: boolean }) {
  const [loading, setLoading] = React.useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] =
  useRecoilState<GridColumnVisibilityModel>(columnVisibilityModelState);
  const [yourProjects, setYourProjects] =
  useRecoilState<ProjectsData[]>(yourProjectsState);
  const yourProjectsWithFav = useRecoilValue<ProjectsData[]>(
    yourProjectsWithFavState
    );
    const reportReady = useRecoilValue<boolean>(reportReadyState);
    const [yourProjectsErrorMessage, setYourProjectsErrorMessage] =
    useRecoilState<string>(yourProjectsErrorMessageState);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const userPhoto = useRecoilValue<string>(userPhotoState);
    const currentUser = localStorage.getItem("apex_mockUser") || account?.name; //"John Read";

  const getData = useCallback(async (isMounted) => {
    setLoading(true);


    window
      .fetch("/api/me/projects")
      .then((r) => r.json())
      .then((response) => {
        if (response && response.items && !response.error) {
          const arr = response.items as Array<any>;
          const pdAndPmItems = arr.filter(i=>i.pd === currentUser && i.pm === currentUser).sort((a,b)=>b.id - a.id);
          const pdOnlyItems = arr.filter(i=>i.pd === currentUser && i.pm !== currentUser).sort((a,b)=>b.id - a.id);
          const pmOnlyItems = arr.filter(i=>i.pd !== currentUser && i.pm === currentUser).sort((a,b)=>b.id - a.id);
          const notPmOrPDItems = arr.filter(i=>i.pd !== currentUser && i.pm !== currentUser).sort((a,b)=>b.id - a.id);
          const sortedByGroups = [...pdAndPmItems, ...pdOnlyItems, ...pmOnlyItems,...notPmOrPDItems];

          setYourProjects(sortedByGroups);
          setYourProjectsErrorMessage("");
        } else {
          setYourProjectsErrorMessage(
            `${
              response.error
                ? response.error
                : "Failed to get your projects from the server"
            }`
          );
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        console.error(`Error retreiving your projects. Detalils: ${e.message}`);
        setLoading(false);
        setYourProjectsErrorMessage(
          `Failed to get your projects from the server. ${e.message}`
        );
      });
  }, []);

  useEffect(() => {
    if (yourProjects.length !== 0) return;

    let isMounted = true;
    console.log(`[YourProjects useEffect] Calling API`);
    getData(isMounted);

    return () => {
      // isMounted = false;
      console.log(`[YourProjects useEffect unmounting]`);
    };
  }, [getData, account]);

  const columns = useMemo(()=>getColumns(reportReady, currentUser, userPhoto),[reportReady, currentUser, userPhoto]);
  
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <DataGrid
        disableVirtualization={props.isNarrowWidth ? true : false}
        headerHeight={props.isNarrowWidth ? 0 : 56}
        autoPageSize={props.isNarrowWidth ? true : false}
        // className="dataGrid"
        // disableExtendRowFullWidth={true}
        error={yourProjectsErrorMessage ? true : undefined}
        // error={true}
        rows={yourProjectsWithFav}
        columns={columns}
        components={{
          Row: props.isNarrowWidth ? DataGridCard : undefined,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () =>
            CustomNoRowsOverlay(
              <>
                By default, this tab is filtered to only show live projects with
                an external client that you are a project manager or project
                director for. If you can't find your project here, please search
                for it in the Search results tab
              </>
            ),
          Toolbar: GridToolbar,
          ErrorOverlay: () =>
            CustomErrorOverlay(
              "Please reload the page or try again later. If the issues continues, please raise a ServiceNow incident for support.",
              yourProjectsErrorMessage
            ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, placeholder: "Quick filter" },
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
          },
        }}
        loading={loading}
        disableSelectionOnClick
        disableDensitySelector
        getRowHeight={() => "auto"}
        sx={{
          fontFamily: "Arial",
          fontSize: "14px",
          color: "#353535",
          // '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "6px",
            "&:focus": {
              outline: "none",
            },
          },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer": {
            height: "40px",
          },
          // '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButton-root": {
            fontFamily: "Arial",
            fontSize: "14px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
            {
              fontWeight: "bold",
            },
          "&.MuiDataGrid-root .favourite--cell": {
            paddingLeft: "2px",
            paddingRight: "2px",
          },
          "&.MuiDataGrid-root .pmPd--cell": {
            gap: "4px",
          },
          "&.MuiDataGrid-root .number--cell": {
            justifyContent: "space-between",
          },
        }}
      />
    </Box>
  );
}

function getColumns(reportReady: boolean, currentUser: string | undefined, photo: string): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: "fav",
      cellClassName: "favourite--cell",
      headerName: "Favourite",
      renderHeader: () => "",
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      minWidth: 15,
      width: 32,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <FavoriteButton
          isFavorite={params.row.fav}
          project={params.row.id}
          tabIndexValue={params.hasFocus ? 0 : -1}
          iconTabIndexValue={-1}
        />
      ),
    },
    {
      field: "id",
      cellClassName: "number--cell",
      headerName: "Number",
      headerAlign: "center",
      minWidth: 124,
      align: "center",
      renderCell: (params: GridRenderCellParams<string>) => {
        return reportReady ? (
          <NavLink
            to={`/project/${params.value}`}
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params.value}
          </NavLink>
        ) : (
          <>
            <a href={`/project/${params.value}`}>{params.value}</a>
            <SubMenu
              projectNumber={params.value || ""}
              apUrl={params.row.apUrl || ""}
              oppGuid={params.row.oppGuid || ""}
              startDt={params.row.startDt}
            />
          </>
        );
      },
      hideable: false,
    },
    {
      field: "n",
      headerName: "Name",
      minWidth: 180,
      flex: 1.5,
      hideable: false,
    },
    {
      field: "pd",
      cellClassName: "pmPd--cell",
      headerName: "Project director",
      minWidth: 150,
      flex: 1,
      // hideable: false,
      // headerClassName:"hidden-header",
      // renderHeader: (params: GridColumnHeaderParams) => null,
      renderCell: (params: GridRenderCellParams<string>) => currentUser === params.row.pd? getNameWithPhoto(photo, params.row.pd): undefined,
    },
    {
      field: "pm",
      cellClassName: "pmPd--cell",
      headerName: "Project manager",
      minWidth: 150,
      flex: 1,
      // hideable: false,
      renderCell: (params: GridRenderCellParams<string>) => currentUser === params.row.pm? getNameWithPhoto(photo, params.row.pm):undefined,
    },
    {
      field: "cm",
      headerName: "Commercial manager",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "fpc",
      headerName: "FPC",
      // description :"FPC or Percent complete",
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "pp",
      headerName: "Profit %",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "lastInvDt",
      headerName: "Last invoice date",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "fuPFF",
      headerName: "Last PFF update",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "wip",
      headerName: "Work in progress",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "cf",
      headerName: "Cashflow",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "accCentre",
      headerName: "Accounting centre",
      minWidth: 150,
      flex: 1.25,
    },
    { field: "client", headerName: "Client", minWidth: 120, flex: 1 },
    {
      field: "grp",
      headerName: "Group",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "ippC",
      headerName: "Contract status",
      minWidth: 150,
      renderCell: addIppIcons,
      type: "singleSelect",
      valueOptions: ["Ok", "Warning", "Critical"],
      filterOperators: [
        ...getGridSingleSelectOperators(),
        {
          value: "isEmpty",
          getApplyFilterFn: () => {
            return ({ value }): boolean => {
              return value === "" || value == null;
            };
          },
          requiresFilterValue: false,
        },
      ],
    },
    // customisable columns
    {
      field: "debt",
      headerName: "Total debts",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
  ];
  return columns;
}
function getNameWithPhoto(photo: string, name: string): React.ReactNode {
  return <>
    {photo ? (
      <Avatar
        alt={name}
        src={photo}
        sx={{
          height: "24px",
          width: "24px",
        }} />
    ) : (
      <NameAvatar name={name} />
    )}
    {name}
  </>;
}

