import * as React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { Avatar, Typography } from "@mui/material";
import msGraphApiService from "../services/ms.graph.api";
import NameAvatar from "./NameAvatar";
import { useRecoilState } from "recoil";
import { userPhotoState } from "../State";

const UserName = ({showName = false}) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = React.useState("");
  const [myPhoto, setMyPhoto] = useRecoilState<string>(userPhotoState);

  const classes = {
    nameRoot: {
      gap: "13px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "56px",
    },
  };
  React.useEffect(() => {
    if (account && account.name) {
      setName(account.name);
      msGraphApiService.getCurrentUserProfilePicture().then((response) => {
        if (response.status === 200) {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(response.data);
          setMyPhoto(blobUrl);
        }
      });
    }
  }, [account]);

  if (name) {
    return (
      <div style={classes.nameRoot}>
        {showName && (
          <Typography
            variant="caption"
            color="inherit"
            sx={{ fontWeight: "normal", fontSize: "12px" }}
          >
            {name}
          </Typography>
        )}
        {myPhoto ? (
          <Avatar
            alt={name}
            src={myPhoto}
            sx={{
              height: "32px",
              width: "32px",
            }}
          />
        ) : (
          <NameAvatar name={name} />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default UserName;
