// icon:chart-line | FontAwesome  5.3.0 Icon https://fontawesome.com/v5/icons/chart-line?s=solid&f=classic
import * as React from "react";

function SkillsNetwork(props: React.SVGProps<SVGSVGElement>) {
  const { fill } = props;
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      // fill="none"
      fill={fill || "#353535"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.5659"
        cy="10.6801"
        r="2.37161"
        transform="rotate(-7.59096 23.5659 10.6801)"
      />
      <circle
        cx="14.1596"
        cy="11.933"
        r="2.37161"
        transform="rotate(-7.59096 14.1596 11.933)"
      />
      <circle
        cx="15.4116"
        cy="21.3344"
        r="2.37161"
        transform="rotate(-7.59096 15.4116 21.3344)"
      />
      <circle
        cx="24.8178"
        cy="20.0825"
        r="2.37161"
        transform="rotate(-7.59096 24.8178 20.0825)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1741 13.6544C20.4724 13.4813 21.3846 12.2886 21.2116 10.9903C21.2099 10.9771 21.208 10.964 21.206 10.9509C22.3101 11.3268 23.2657 12.0576 23.9176 13.0246C23.9036 13.0263 23.8896 13.028 23.8756 13.0299C22.5773 13.2029 21.665 14.3957 21.8381 15.694C22.0111 16.9923 23.2039 17.9045 24.5022 17.7315C24.5155 17.7297 24.5287 17.7279 24.5419 17.7259C24.1656 18.8277 23.4357 19.7813 22.4704 20.4323C22.4688 20.4194 22.4672 20.4065 22.4655 20.3936C22.2925 19.0952 21.0997 18.183 19.8014 18.356C18.5031 18.5291 17.5908 19.7218 17.7639 21.0202C17.7655 21.0325 17.7672 21.0448 17.7691 21.0571C16.6677 20.6811 15.7144 19.9517 15.0634 18.9871C15.0755 18.9856 15.0877 18.9841 15.0999 18.9825C16.3982 18.8095 17.3104 17.6167 17.1374 16.3184C16.9644 15.02 15.7716 14.1078 14.4733 14.2808C14.4611 14.2825 14.4489 14.2842 14.4368 14.286C14.8123 13.1846 15.5411 12.2312 16.5053 11.5798C16.5068 11.5922 16.5083 11.6045 16.5099 11.6168C16.683 12.9152 17.8757 13.8274 19.1741 13.6544Z"
      />
      <circle
        cx="8.50082"
        cy="3.24033"
        r="2.37161"
        transform="rotate(-60 8.50082 3.24033)"
      />
      <circle
        cx="3.75668"
        cy="11.4561"
        r="2.37161"
        transform="rotate(-60 3.75668 11.4561)"
      />
      <circle
        cx="11.9735"
        cy="16.1983"
        r="2.37161"
        transform="rotate(-60 11.9735 16.1983)"
      />
      <circle
        cx="16.7157"
        cy="7.98252"
        r="2.37161"
        transform="rotate(-60 16.7157 7.98252)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17971 8.53459C8.83462 7.40026 8.44597 5.94981 7.31164 5.29491C7.30053 5.28849 7.28939 5.28218 7.27823 5.27597C8.24909 4.63069 9.41046 4.31926 10.5739 4.39217C10.5666 4.40423 10.5595 4.41637 10.5524 4.4286C9.89752 5.56293 10.2862 7.01338 11.4205 7.66828C12.5548 8.32319 14.0053 7.93454 14.6602 6.80021C14.6671 6.78815 14.674 6.77605 14.6807 6.76391C15.325 7.73459 15.6357 8.89536 15.5625 10.0581C15.5512 10.0513 15.5399 10.0447 15.5284 10.0381C14.3941 9.38317 12.9437 9.77182 12.2888 10.9061C11.6339 12.0405 12.0225 13.4909 13.1568 14.1458C13.1677 14.1521 13.1786 14.1583 13.1895 14.1644C12.2194 14.8078 11.0596 15.1183 9.89767 15.0454C9.90386 15.035 9.90999 15.0246 9.91604 15.0141C10.5709 13.8798 10.1823 12.4293 9.04797 11.7744C7.91364 11.1195 6.46319 11.5081 5.80829 12.6425C5.80241 12.6527 5.79661 12.6629 5.7909 12.6731C5.14785 11.704 4.83721 10.5454 4.90915 9.38453C4.91938 9.39064 4.92968 9.39668 4.94003 9.40266C6.07435 10.0576 7.52481 9.66892 8.17971 8.53459Z"
      />
    </svg>
  );
}

export default SkillsNetwork;
