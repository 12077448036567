import { DocumentHead } from "../common/DocumentHead";
import { Banner } from "../components/Banner";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  searchResultsState,
  selectedTabState,
  searchInProgressState,
  searchReturnedNoResultState,
} from "../State";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import YourProjects from "../components/YourProjects";
import AllProjects from "../components/AllProjects";
import { ProjectsData } from "../types/ProjectsData";
import Favourites from "../components/Favourites";
import GroupProjects from "../components/GroupProjects";

const LandingPage = () => {
  const theme = useTheme();
  const isNarrowWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const projects = useRecoilValue<ProjectsData[]>(searchResultsState);
  const searchInProgress = useRecoilValue<boolean>(searchInProgressState);
  const searchReturnedNoResult = useRecoilValue<boolean>(
    searchReturnedNoResultState
  );
  const [selectedTab, setSelectedTab] =
    useRecoilState<number>(selectedTabState);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setSelectedTab(newValue);
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    const adjustHeightForSearchStatus =  selectedTab === 2 && (searchInProgress || projects.length > 0 ||
      (!searchInProgress && searchReturnedNoResult));
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`projects-tabpanel-${index}`}
        aria-labelledby={`projects-tab-${index}`}
        style={{
          //height: adjustHeightForSearchStatus ? "calc(100% - 48px - 36px)" : "calc(100% - 48px)",
          // Above code is commented for bug reported : 137368
          height: "calc(100% - 48px)",
        }}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `projects-tab-${index}`,
      "aria-controls": `projects-tabpanel-${index}`,
    };
  };

  const searchResultsMessage = (searchInProgress || projects.length > 0 ||
    (!searchInProgress && searchReturnedNoResult)) && (
    <div
      style={{
        fontFamily: "Arial" /* Arup Subtitle XS */,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        textAlign: "center",
        letterSpacing: "0.6px",
        color: "#575757",  /* arc-grey/070 */
        ...(isNarrowWidth? {
          lineHeight: "20px",
          padding: "8px",
        }:{
          lineHeight: "48px",
        })
        // height: 20px;
        // line-height: 20px !IMPORTANT;
        // margin: 8px;
        
      }}
    >
      {searchInProgress
        ? `Searching for projects...`
        : projects.length > 0
        ? `${projects.length} results. Refine using filter.`
        : ""}
      {!searchInProgress &&
        searchReturnedNoResult &&
        "No results found. Try searching with a different keyword."}
    </div>
  );

  return (
    <>
      <DocumentHead title="Apex Portal" projectNumber="" subPageTitle=""/>
      <Banner isNarrowWidth={isNarrowWidth} />
      <Box
        sx={{
          ...(!isNarrowWidth && {
            borderBottom: 1,
            borderColor: "divider",
            width: 1172,
            flex: "1",
          }),
          minHeight: "448px",
        }}
      >
        <div style={{ display: "flex", gap: "16px" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="Projects tabs"
          >
            <Tab
              label="Your projects"
              {...a11yProps(0)}
              disableRipple={true}
              sx={{
                textTransform: "none",
                fontFamily: "Arial",
                fontWeight: "bold",
              }}
            />
            <Tab
              label="Favourites"
              {...a11yProps(1)}
              disableRipple={true}
              sx={{
                textTransform: "none",
                fontFamily: "Arial",
                fontWeight: "bold",
              }}
            />
            <Tab
            label="Group projects"
            {...a11yProps(2)}
            disableRipple={true}
            sx={{
              textTransform: "none",
              fontFamily: "Arial",
              fontWeight: "bold"
            }}
            />
            <Tab
              label="Search results"
              {...a11yProps(3)}
              disableRipple={true}
              sx={{
                textTransform: "none",
                fontFamily: "Arial",
                fontWeight: "bold",
              }}
            />
          </Tabs>
          {!isNarrowWidth && selectedTab === 2 && searchResultsMessage}
        </div>
        {isNarrowWidth && selectedTab === 2 && searchResultsMessage}
        <TabPanel value={selectedTab} index={0}>
          <YourProjects isNarrowWidth={isNarrowWidth}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Favourites isNarrowWidth={isNarrowWidth}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <GroupProjects isNarrowWidth={isNarrowWidth}/>
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <AllProjects isNarrowWidth={isNarrowWidth}/>
        </TabPanel>
      </Box>
    </>
  );
};

export default LandingPage;
