import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ListItemIcon, Typography, Button } from "@mui/material";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { HtmlTooltip } from "./HtmlTooltip";
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TagManager from 'react-gtm-module';
import { useRecoilValue } from 'recoil';
import { userDataState } from '../State';
import { UserData } from '../types/UserData';
import posthog from 'posthog-js'

type SubMenuItem = {
  name: string;
  url: string;
  noDataUrl: string;
  showVpnIcon?: boolean;
};

const menuItems: SubMenuItem[] = [
  {
    name: "Arup Projects",
    url: "",
    noDataUrl:
      "https://arup.sharepoint.com/sites/Apex/SitePages/NoArupProjectRecord.aspx",
  },
  {
    name: "DIP",
    url: "https://dip.arup.com/?project={id}",
    noDataUrl: "",
  },
  {
    name: "DMM",
    url: "https://digitalmaturitymeasure.arup.com/app/projects/{id}/project-details",
    noDataUrl: "",
    showVpnIcon: true,
  },
  {
    name: "Dynamics CRM",
    url: "https://arupcrm.crm11.dynamics.com/main.aspx?etc=3&id=%7b{oppGuid}%7d&pagetype=entityrecord",
    noDataUrl:
      "https://arup.sharepoint.com/sites/Apex/SitePages/NoCRMdata.aspx",
  },
  {
    name: "Fee Portal",
    url: "https://feeportal.arup.com/ArupFeePortal/ViewInvoiceDetail.aspx?JobNo={id}",
    noDataUrl: "",
  },
  {
    name: "GRM / PaT",
    url: "https://pat.arup.com/project/full/{id}/resource",
    noDataUrl: "",
  },
  {
    name: "IPP",
    url: "https://ipp.arup.com/navigator/#{id}",
    noDataUrl: "",
    showVpnIcon: true,
  },
  {
    name: "Launchpad",
    url: "https://launchpad.arup.com/project/{id}/summary",
    noDataUrl: "",
    showVpnIcon: true,
  },
  {
    name: "PFF",
    url: "https://pff.arup.com/dashboard/viewdashboard?ProjectNumber={id}",
    noDataUrl: "",
  },
  {
    name: "Project Finder",
    url: "https://project-finder.arup.com/project/{id}",
    noDataUrl: "",
  },
];
export default function SubMenu(props: {
  projectNumber: string;
  apUrl: string;
  oppGuid: string;
  isButton?: boolean;
  startDt?: Date
}) {
  const { projectNumber, apUrl, oppGuid, isButton , startDt} = props;
  //PBI no 144934
  const HSEQDate  = new Date("2023-10-06T00:00:00Z");
  let isLaunchpad = false;
  const userData = useRecoilValue<UserData>(userDataState);
  const region = (userData?.r);
  if (region === "UKIMEA Region" && startDt !== undefined) {
    const startDate = new Date(startDt);
    if (startDate >= HSEQDate) {
      isLaunchpad = true;
    }
  }
  //const isLaunchpad = launchpad;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };
  const menuItemOnClicked = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    addTags(target.textContent?.toString());
    event.stopPropagation();
    setAnchorEl(null);
  };

  const addTags = (eventTitle?: string) => {
    //Add Posthog capture event
    posthog.capture('OpenProjectIn_Click', {
      page_title: eventTitle
    });
    const tagManagerArgs = {
      gtmId: "GTM-KPF2RLX",
    };
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer({
      dataLayer: {
        event: 'OpenProjectIn_Click', // event name declared during initialization
        page_title: eventTitle
      }
    });
  }

  return (
    // <div
    //   style={{
    //     color: "#575757",
    //   }}
    // >
    <>
      {!isButton ? <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        // color="primary"
        sx={{
          padding: "5px",
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton> : <Button sx={{ textTransform:"none"}} variant="outlined" size="small" color="inherit" style={{borderRadius: "28px", border: "1px solid", background: "#FFF", borderColor: "#353535", padding: "0px 16px", height: "36px"}} id="OpenProjectIn_Click" onClick={handleClick} startIcon={<LaunchIcon />} endIcon={<ExpandMoreIcon />}>
        Open project in
      </Button>}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "160px",
            /* Arup Body 3 */
            fontFamily: "'Arial'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            /* identical to box height, or 167% */
            letterSpacing: "0.65px",
            /* arc-grey/070 */
            color: "#575757",
            padding: "6px 0 6px",
            /* arc-white/000 */
            background: "#FFFFFF",

            "& .MuiList-root": {
              p: 0,
              "& .MuiMenuItem-root": {
                fontSize: "12px",
                minHeight: "unset",
                height: "32px",
                lineHeight: "32px",
                padding: "0px 16px 0px",
                display: "flex",
                gap: "8px",
                "& .MuiSvgIcon-root": {
                  width: "16px",
                },
              },
            },
          },
        }}
      >
        {!isButton && <div
          style={{
            gap: "8px",
            alignItems: "center",
            display: "inline-flex",
            padding: "0px 16px 0px",
          }}
        >
          <OpenInNewIcon sx={{ fontSize: "16px" }} />
          Open project in:
        </div>}
        {menuItems &&
          menuItems.length > 0 &&
          menuItems.map((item: any) => (
            <HtmlTooltip
              disableFocusListener
              disableInteractive
              title={
                item.showVpnIcon && (
                  <>
                    <Typography
                      fontSize="12px"
                      variant="body1"
                      display="block"
                      color="inherit"
                      gutterBottom
                    >
                      {`Navigate to ${item.name}`}. This tool requires a
                      connection to Arup’s VPN or network
                    </Typography>
                  </>
                )
              }
              key={`${projectNumber}-${item.name}`}
            >
              {(isLaunchpad && item.name === "IPP") || (!isLaunchpad && item.name === "Launchpad") ? <></> : <MenuItem
                disableGutters
                href={getUrl(item, projectNumber, apUrl, oppGuid)}
                title={getTooltip(item)}
                target="_blank"
                component="a"
                rel="noopener noreferrer"
                key={`${projectNumber}-${item.name}`}
                onClick={menuItemOnClicked}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {item.name}{" "}
                {item.showVpnIcon && (
                  <ListItemIcon>
                    <VpnLockIcon fontSize="small" />
                  </ListItemIcon>
                )}
              </MenuItem>}
            </HtmlTooltip>
          ))}
      </Menu>
    </>
    //</div>
  );

  function getTooltip(item: SubMenuItem): string | undefined {
    if (item.name === "Arup Projects" && !apUrl)
      return "This project does not have a record in Arup Projects";

    if (item.name === "Dynamics CRM" && !oppGuid)
      return "This project does not have a record in CRM";

    return "";
  }
  function getUrl(
    item: SubMenuItem,
    projectNumber: string,
    apUrl: string,
    oppGuid: string
  ): string {
    switch (item.name) {
      case "Arup Projects":
        return apUrl || item.noDataUrl;
      case "Dynamics CRM":
        return oppGuid
          ? item.url.replace("{oppGuid}", oppGuid)
          : item.noDataUrl;
      default:
        return item.url.replace("{id}", projectNumber) || "";
    }
  }
}
