import React, { useState, createContext } from "react";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

export const ReactThemeContext = createContext({
  darkMode: false,
  toggleDarkMode: () => {},
});

export const ReactThemeProvider = ({ children }:any) => {
  const selectedTheme = localStorage.getItem("reactTemplateTheme");
  const [darkMode, setDarkMode] = useState<boolean>(
    selectedTheme === "true" ? true : false,
  );
  function toggleDarkMode() {
    setDarkMode((darkMode) => !darkMode);
    localStorage.setItem("reactTemplateTheme", JSON.stringify(!darkMode));
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#E61E28",
        light: "#FF6A6A",
        dark: "#A60606",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#28AF73",
        light: "#BAFFB9",
        dark: "#056937",
        contrastText: "#ffffff",
      },
      background: {
        default: !darkMode ? "#ffffff" : "#303030",
        paper: !darkMode ? "#EFEFEF" : "#424242",
      },
    },
    typography: {
      fontFamily: "Arial, Helvetica, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontSize: 16,
      caption: {
        fontSize: 16,
        fontWeight: 400,
      },
      h1: {
        fontSize: 128,
      },
      h2: {
        fontSize: 80,
      },
      h3: {
        fontSize: 64,
      },
      h4: {
        fontSize: 44.8,
      },
      h5: {
        fontSize: 32,
      },
      h6: {
        fontSize: 20,
      },
      button: {
        fontSize: 16,
        textTransform: "capitalize",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  return (
    <ReactThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ReactThemeContext.Provider>
  );
};
