import React, { useCallback, useState, useEffect } from "react";
import SubMenu from "../common/SubMenu";
import { JSONResponse } from "../types/JSONResponse";
import { Button, useMediaQuery, DialogContent, Typography, DialogActions } from "@mui/material";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LockIcon from '@mui/icons-material/Lock';
import {
    AnnouncementDialog,
    AnnouncementDialogTitle,
} from "./AnnouncementDialog";

const OpenProjectTopBar = ({ projectId = "", isAMS = true, pageName = "" }) => {
    const isNarrowWidth = !useMediaQuery("(min-width:600px)");
    const [shortTitle, setShortTitle] = useState('');
    const [apURL, setapURL] = useState('');
    const [oppGUID, setoppGUID] = useState('');
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [region, setRegion] = useState('');
    const pageTitle = (pageName === undefined || pageName === "" || pageName === "ProjectDetails") ? "" : ((pageName === "Financial report") ? "Project financial report | " : pageName.concat(" | "));
    const [compAccess, setcompAccess] = useState(true);
    const [noAccessMessage, setnoAccessMessage] = useState('');
    const [openAnnouncement, setOpenAnnouncement] = useState<any>(undefined);
    const paddingLeftVal = isNarrowWidth ? "8px" : "23px";

    useEffect(() => {
        if (projectId) {
            getData(projectId);
        }
    }, [projectId])

    const getData = useCallback(async (searchText: string) => {
        try {
            const response = await fetch(`/api/projects?k=${searchText}`);

            const { items, error }: JSONResponse = await response.json();
            if (response.ok) {
                if (items && items.length > 0) {
                    const processActualJob = items.map((i) => {
                        setcompAccess(i.ca);
                        setnoAccessMessage(i.cc + '-' + i.cn);
                        setShortTitle(i.n);
                        let ap = i.apUrl;
                        if (ap)
                            setapURL(ap);
                        let opp = i.oppGuid;
                        if (opp)
                            setoppGUID(opp);
                        let startDt = i.startDt;
                        if (startDt){
                            setStartDate(startDt);
                            localStorage.setItem("StartDate", startDt.toString());
                        }
                        //Set Project Region Name
                        let rn = i.rn;
                        if(rn)
                        setRegion(rn);
                        return i.n;
                    });
                    return;
                }else{
                    console.log('No result found');
                }
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const goToAMSURL = (region?: string) => {
        switch (region) {
            case "Americas Region":
                return "https://arup.sharepoint.com/sites/Essentials-Americas/ams/Pages/AMS-Procedures.aspx"
            case "Australasia Region":
                return "https://arup.sharepoint.com/sites/essentials-australasia-delivering-projects"
            case "East Asia Region":
                return "https://arup.sharepoint.com/sites/Essentials-EastAsia/ams/Pages/Projects.aspx"
            case "Europe":
                return "https://arup.sharepoint.com/sites/essentials-europe-ams/SitePages/AMS-on-a-Page.aspx"
            case "UKIMEA Region":
                return "https://arup.sharepoint.com/sites/essentials-ukimea-ams/SitePages/Full-List-of-Processes.aspx"
            default:
                return "https://arup.sharepoint.com/sites/essentials-global/ams/"
        };
    }

    const amsClick = (event: any) => {
        window.open(goToAMSURL(region));
    };

    const openAnnouncementDialog = (item: any) => {
        setOpenAnnouncement(item);
    };

    const closeAnnouncementDialog = () => {
        setOpenAnnouncement(undefined);
    };

    return (
        <>
            {!isNarrowWidth && (<><div className="report-style-class" style={{ paddingLeft: "23px", wordWrap: "break-word", paddingRight: "12px", height: "32px", display: "flex", justifyContent: "space-between", fontFamily: "Times New Roman", fontSize: "larger", fontStyle: "normal", fontWeight: "normal", textAnchor: "start" }}><p style={{ textAlign: "left" }}> <b>{pageTitle}</b> {projectId} {shortTitle}</p> <p style={{ textAlign: "right" }}>{!isAMS && <Button variant="outlined" size="small" color="inherit" style={{ borderRadius: "28px", border: "1px solid", background: "#FFF", borderColor: "#353535", padding: "0px 16px", height: "36px" }} id="AMS_Click" onClick={amsClick} startIcon={<FormatListBulletedIcon />}>
                AMS
            </Button>} <SubMenu isButton={true} projectNumber={projectId} apUrl={apURL} oppGuid={oppGUID} startDt={startDate}/></p></div>
            {!compAccess && <div className="report-style-class" style={{ paddingLeft: paddingLeftVal, paddingRight: "12px", height: "18px", display: "flex", justifyContent: "space-between", fontFamily: "Times New Roman", fontSize: "xx-small", fontStyle: "normal", fontWeight: "normal", textAnchor: "start" }}>
                <p style={{ textAlign: "left" }}><Button variant="outlined" style={{ font: "small-caption", backgroundColor: "#FFEEEF", fontSize: "larger" }} size="small" sx={{ textTransform: "none" }} startIcon={<LockIcon />} id="NoAccess_Click" onClick={openAnnouncementDialog}>No access to financial data for company {noAccessMessage}</Button></p></div>}
            </>)}

            {isNarrowWidth && (<>
                <div style={{ textAlign: "left", fontFamily: "Times New Roman", fontSize: "larger", fontStyle: "normal", fontWeight: "400", letterSpacing: "0.4px", textAnchor: "start", wordWrap: "break-word", maxWidth: "100%", paddingLeft: "8px" }}> <b>{pageTitle}</b> {projectId} {shortTitle}</div>
                <div style={{ textAlign: "right" }}>{!isAMS && <Button variant="outlined" size="small" color="inherit" style={{ borderRadius: "28px", border: "1px solid", background: "#FFF", borderColor: "#353535", padding: "0px 16px", height: "36px" }} id="AMS_Click" onClick={amsClick} startIcon={<FormatListBulletedIcon />}>
                    AMS
                </Button>} <SubMenu isButton={true} projectNumber={projectId} apUrl={apURL} oppGuid={oppGUID} startDt={startDate}/></div>
                {!compAccess && <div className="report-style-class" style={{ paddingLeft: paddingLeftVal, paddingRight: "12px", display: "flex", justifyContent: "space-between", fontFamily: "Times New Roman", fontSize: "xx-small", fontStyle: "normal", fontWeight: "normal", textAnchor: "start" }}>
                <p style={{ textAlign: "left" }}><Button variant="outlined" style={{ font: "small-caption", backgroundColor: "#FFEEEF", fontSize: "larger" }} size="small" sx={{ textTransform: "none" }} startIcon={<LockIcon />} id="NoAccess_Click" onClick={openAnnouncementDialog}>No access to financial data for company {noAccessMessage}</Button></p></div>}
            </>)}

            {openAnnouncement && (
                <AnnouncementDialog
                    onClose={closeAnnouncementDialog}
                    aria-labelledby="customized-dialog-title"
                    open={openAnnouncement ? true : false}
                >
                    {openAnnouncement && (
                        <AnnouncementDialogTitle
                            id="customized-dialog-title"
                            onClose={closeAnnouncementDialog}
                        >
                            You don't have access to this project's financial data
                        </AnnouncementDialogTitle>
                    )}
                    <DialogContent dividers>
                        <Typography
                            gutterBottom
                            dangerouslySetInnerHTML={{
                                __html: "<p>To request access go to <a href='https://arup.service-now.com/sp' target='_blank'>Service Now</a> and request Ovaview access to the Company (" + noAccessMessage + ") this project is in. Alternatively, go <a href='https://arup.sharepoint.com/sites/RPACOEPowerPlatformUsers/SitePages/Ovaview-Access-RequestGlobal.aspx' target='_blank'>directly to the request access form-Ovaview financial access</a>.</p>"
                            }}
                        />
                        <Typography
                            gutterBottom
                            dangerouslySetInnerHTML={{
                                __html: "<p>Data is displayed as follows:<br/> No access to financial data: Tables will be empty. Cards will display a &quot;(blank)&quot; value.<br/> No data available: Table rows with no data won't be displayed. Cards will display a &quot;-&quot; or &quot;0&quot; value.</p>"
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <arc-button
                            type="outlined"
                            color="secondary"
                            size="medium"
                            onClick={closeAnnouncementDialog}
                            style={{ color: "cyan" }}
                        >
                            Close
                        </arc-button>
                    </DialogActions>
                </AnnouncementDialog>
            )}
        </>
    )
};

export default OpenProjectTopBar;
