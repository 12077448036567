import * as React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import { JSONResponse } from "../types/JSONResponse";
import { ProjectsData } from "../types/ProjectsData";
import { useRecoilState, useRecoilValue } from "recoil";
import { favouritesErrorMessageState, favouritesState } from "../State";
import { CircularProgress } from "@mui/material";

const tooltips = {
  Disable: "",
  Favourite: "Click to add to favourites",
  Unfavourite: "Click to remove from favourites",
  FavouritesUnavailable: "Temporarily unavailable. If the issue continues, please raise a ServiceNow incident for support",
  FavouritesAllowedLimit: "Maximum of 20 projects can be added as favourites"
};

interface IFavoriteButtonProps {
  isFavorite: boolean;
  project: string;
  disableTooltip?: boolean;
  openTooltip?: boolean;
  tabIndexValue?: number;
  iconTabIndexValue?: number;
  onStatusChange?: (project: string, newStatus: boolean) => void;
}
export type FavoritePayload = {
  projects: string[];
};

export const FavoriteButton: React.FunctionComponent<IFavoriteButtonProps> = (
  props: IFavoriteButtonProps
): JSX.Element => {
  const [favourites, setFavourites] =
    useRecoilState<ProjectsData[]>(favouritesState);
  const favouritesErrorMessage = useRecoilValue<string>(favouritesErrorMessageState);

  const [isLoading, setLoading] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState<{
    message?: string;
    show: boolean;
  } | null>({ show: false });

  const updateFavourites = async (body: string[]) => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          favourites: body,
        }),
        // redirect: "follow",
      };

      const response = await window.fetch("/api/me/settings", requestOptions);

      const { items, error }: JSONResponse = await response.json();
      console.log(items, error);
      if (response.ok && items && error === "") {
        setFavourites(items);
        setLoading(false);
      } else {
        setSnackBar({
          show: true,
          message: error
            ? `Failed to update favourites. ${error}. Try again or contact support`
            : "Failed to update favourites. Try again or contact support",
        });
        setLoading(false);

        if (error) console.error(error);
      }
    } catch (error) {
      console.error(error);
      setSnackBar({
        show: true,
        message: error
          ? `Failed to update favourites. ${error}. Try again or contact support`
          : "Failed to update favourites. Try again or contact support",
      });
      setLoading(false);
    }
  };

  const addFavorite = async (evt: any, project: string, status: string) => {
    evt.stopPropagation();
    if (!project) return;

    const ids = favourites.map((p) => p.id);
    ids.push(project);
    await updateFavourites(ids);

    // setSnackBar({
    //   show: false,
    //   message: "Project was successfully added to your favourites.",
    // });
  };

  const removeFavorite = async (evt: any, project: string, status: string) => {
    evt.stopPropagation();
    if (!project) return;

    console.log(`Favourite clicked - removing:${project}, evt:`, evt);

    const projectsToUpdate = favourites.filter((i) => i.id !== project);
    const ids = projectsToUpdate.map((p) => p.id);
    await updateFavourites(ids);

    // setSnackBar({
    //   show: false,
    //   message: "Project was successfully removed from your favourites.",
    // });
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    if (snackBar?.show === true) {
      setSnackBar({
        show: false,
        message: "",
      });
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  if (isLoading) {
    return <CircularProgress title="Updating ..." size={30} />;
  }

  const addFavouriteToolTipMessage = favouritesErrorMessage
    ? tooltips.FavouritesUnavailable
    : favourites.length >= 20
    ? tooltips.FavouritesAllowedLimit
    : tooltips.Favourite;

  const removeFavouriteToolTipMessage = favouritesErrorMessage
    ? tooltips.FavouritesUnavailable
    : tooltips.Unfavourite;

  return props.isFavorite ? (
    <>
      <Tooltip
        title={props.disableTooltip ? tooltips.Disable : removeFavouriteToolTipMessage}
        enterDelay={400}
        open={props.openTooltip}
      >
        <IconButton
          color="inherit"
          tabIndex={props.tabIndexValue}
          disableRipple
          sx={{ padding: "0px" }}
          onClick={(evt) =>
            props.project
              ? removeFavorite(evt, props.project, "props.jobStatus")
              : void 0
          }
        >
          <StarIcon
            sx={{ cursor: "pointer" }}
            tabIndex={props.iconTabIndexValue}
          />
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackBar?.show}
        autoHideDuration={4000}
        message={snackBar?.message}
        // onClose={handleClose}
        action={action}
      />
    </>
  ) : (
    <>
      <Tooltip
        color="inherit"
        // title={props.disableTooltip ? tooltips.Disable : tooltips.Favourite}
        title={
          props.disableTooltip
            ? tooltips.Disable
            : addFavouriteToolTipMessage
        }
        enterDelay={400}
        open={props.openTooltip}
      >
        <span>
          <IconButton
            disabled={favourites.length >= 20 || favouritesErrorMessage !== ""}
            tabIndex={props.tabIndexValue}
            disableRipple
            sx={{ padding: "0px" }}
            onClick={(evt: any) =>
              props.project && favourites.length < 20
                ? addFavorite(evt, props.project, "")
                : void 0
            }
          >
            <StarBorderIcon
              sx={{ cursor: "pointer" }}
              tabIndex={props.iconTabIndexValue}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Snackbar
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={snackBar?.show}
        autoHideDuration={4000}
        message={snackBar?.message}
        // onClose={handleClose}
      />
    </>
  );
};
