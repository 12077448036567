import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { AuthToken } from "./authenticate";

// make a call to the api using specified url, http method and body + auth stuff
const spService = async (
	url: string,
	method: string,
	instance: IPublicClientApplication,
	accounts: AccountInfo[],
	contentType = "application/json",
	body?: string,
) => {
	let bearer = await AuthToken(instance, accounts);
	let headers = new Headers();
	headers.append("Authorization", bearer);
	contentType !== "multipart/form-data" &&
		headers.append("Content-Type", contentType);
	headers.append("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly");
	headers.append("Accept", "application/json;odata.metadata=none");

	let options = {};
	if (!body) {
		options = {
			method: method,
			headers: headers,
		};
	} else {
		body = contentType === "multipart/form-data" ? body : JSON.stringify(body);
		options = {
			method: method,
			body: body,
			headers: headers,
		};
	}

	const splistEndPoint = `https://graph.microsoft.com/v1.0/sites/c8102923-b26e-42c2-9d3f-2e6050604ca4/lists/1f4be55a-9fcc-49fd-b66e-52202a965245`;

	const res = await fetch(splistEndPoint + url, options);
	if (method === "GET") {
		const json = await res.json();
		return json;
	} else {
		return res;
	}
};

export default spService;
