import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import { Header } from "./components/Header";
import Layout from "./pages/Layout";
import LandingPage from "./pages/LandingPage";
import PageNotFound from "./pages/PageNotFound";
import Project from "./pages/Project";
import ProjectSubPage from "./pages/ProjectSubPage";
import { ReportPage } from "./config/appConfig";
import "./App.css";

function ErrorComponent(error: MsalAuthenticationResult) {
  return <p>An Error Occurred: {error.error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

function App() {
  console.log(`[App render]`);
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <div className="full-screen">
        <Header />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path="project/:id" element={<Project />}>
              <Route
                path="details"
                element={
                  <ProjectSubPage title={ReportPage.Details.displayName} />
                }
              />
              <Route
                path="financial-report"
                element={
                  <ProjectSubPage
                    title={ReportPage.FinancialReport.displayName}
                  />
                }
              />
              <Route
                path="job-financial-report"
                element={
                  <ProjectSubPage
                    title={ReportPage.JobFinancialReport.displayName}
                  />
                }
              />
              <Route
                path="expenses"
                element={
                  <ProjectSubPage title={ReportPage.Expenses.displayName} />
                }
              />
              {/* <Route
                path="compare-expenses"
                element={
                  <ProjectSubPage
                    title={ReportPage.CompareExpenses.displayName}
                  />
                }
              /> */}
              <Route
                path="invoices"
                element={
                  <ProjectSubPage title={ReportPage.Invoices.displayName} />
                }
              />
              <Route
                path="timesheets"
                element={
                  <ProjectSubPage
                    title={ReportPage.Timesheets.displayName}
                  />
                }
              />
              <Route
                path="staff-resourcing"
                element={
                  <ProjectSubPage
                    title={ReportPage.StaffResourcing.displayName}
                  />
                }
              />
              <Route
                path="hours-and-costs"
                element={
                  <ProjectSubPage
                    title={ReportPage.HoursAndCosts.displayName}
                  />
                }
              />
              <Route
                path="site-visit-training-compliance"
                element={
                  <ProjectSubPage
                    title={ReportPage.SiteVisitTrainingCompliance.displayName}
                  />
                }
              />
              <Route
                path="unexpected-time-bookings"
                element={
                  <ProjectSubPage
                    title={ReportPage.UnexpectedTimeBookings.displayName}
                  />
                }
              />
              <Route
                path="opportunities"
                element={
                  <ProjectSubPage
                    title={ReportPage.Opportunities.displayName}
                  />
                }
              />
              <Route
                path="key-dates"
                element={
                  <ProjectSubPage
                    title={ReportPage.KeyDates.displayName}
                  />
                }
              />
              <Route
                path="project-team-members"
                element={
                  <ProjectSubPage
                    title={ReportPage.ProjectTeamMembers.displayName}
                  />
                }
              />
            </Route>
            {/* <Route path="/login" element={
            // <LandingPage />
            <Navigate to="/" replace={true} /> //, {replace:true});

          } /> */}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </MsalAuthenticationTemplate>
  );
}

export default App;
