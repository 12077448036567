import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Breadcrumbs, Button, Link } from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
interface IBreadcrumb {
  projectText: string;
  projectSubPage: string;
  onHomeLinkClicked: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onProjectTextClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
}
export default function Breadcrumb(props: IBreadcrumb) {
  if (props.projectText) {
    console.log(`[Breadcrumb]`, props);
  }
  const styles = {
    fontFamily: "inherit !Important",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "14px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        padding: "16px 12px 16px 16px",
        height: "52px",
        minWidth: "fit-content"
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ color: "inherit", fontSize: "14px", lineHeight: "14px" }}
      >
        <Button
          sx={styles}
          color="inherit"
          onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
            props.onHomeLinkClicked(event)
          }
          startIcon={<HomeOutlinedIcon color="inherit"/>}
          
        >
           Home
        </Button>
        {!props.projectSubPage && props.projectText && (
          <Typography variant="inherit" color="#9E9E9E" sx={styles}>
            {props.projectText}
          </Typography>
        )}
        {props.projectSubPage && (
          <Link
            sx={styles}
            underline="hover"
            component="button"
            variant="inherit"
            color="inherit"
            onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
              props.onProjectTextClick(event)
            }
          >
            {props.projectText}
          </Link>
        )}

        {props.projectSubPage && (
          <Typography color="#9E9E9E" sx={styles}>{props.projectSubPage}</Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
}
