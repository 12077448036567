import { LogLevel } from "@azure/msal-browser";

const logLevel = localStorage.getItem("ApexDebug");
const loggerOptions = {
  logLevel: logLevel ? LogLevel.Verbose : LogLevel.Error,
  loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
    if (logLevel) console.log(`[MSAL]logLevel is ${level}`);
    
    if (containsPii) {
        return;
    }
    switch (level) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
    }
  },
};

export const msalConfig = {
  auth: {
    // clientId: "846b097f-6a60-425a-871d-46d012ac0e63",
    clientId: "f5efce10-52a1-43b7-876c-bd818bf0039a",
    authority:
      "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // "https://login.windows-ppe.net/common",
    redirectUri: "/",
    postLogoutRedirectUri: "/logout",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions,
  },
};
  
  export const siteRead = {
    scopes: ["Sites.Read.All"],
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: [
      "openid", "profile",
      "User.Read",
      // "https://analysis.windows.net/powerbi/api/Report.Read.All",
      // "https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All",
    ],
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };