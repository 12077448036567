import * as React from "react";
import {
  Box,
  LinearProgress,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridToolbar,
  GridRenderCellParams,
  GridValueFormatterParams,
  getGridSingleSelectOperators,
  GridColumnVisibilityModel,
  // GridToolbarContainer,
  // GridToolbarColumnsButton,
  // GridToolbarFilterButton,
  // GridToolbarContainerProps,
  // GridToolbarQuickFilterProps,
  // GridToolbarExportProps,
} from "@mui/x-data-grid";
import { ProjectsData } from "../types/ProjectsData";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  columnVisibilityModelState,
  searchInProgressState,
  searchResultsState,
  searchResultsWithFavState,
  searchReturnedNoResultState,
} from "../State";
import {
  addIppIcons,
  addPadLockIfNoAccess,
  appendPercentSymbol,
  monthNames,
  numberFormatter,
} from "../utils/dataGridHelpers";
import { FavoriteButton } from "../common/FavoriteButton";
import { CustomNoRowsOverlay } from "../common/DataGridOverlays";
import SubMenu from "../common/SubMenu";
import { DataGridCard } from "./DataGridCard";

export default function AllProjects(props: { isNarrowWidth?: boolean }) {
  const [columnVisibilityModel, setColumnVisibilityModel] =
  useRecoilState<GridColumnVisibilityModel>(columnVisibilityModelState);
  const projects = useRecoilValue<ProjectsData[]>(searchResultsState);
  const searchResultsWithFav = useRecoilValue<ProjectsData[]>(
    searchResultsWithFavState
  );
  const searchInProgress = useRecoilValue<boolean>(searchInProgressState);
  const searchReturnedNoResult = useRecoilValue<boolean>(
    searchReturnedNoResultState
  );
  const columns = React.useMemo(()=>getColumns(),[]);
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <DataGrid
        disableVirtualization={props.isNarrowWidth ? true : false}
        headerHeight={props.isNarrowWidth ? 0 : 56}
        autoPageSize={props.isNarrowWidth ? true : false}
        // error={"test"}
        // filterMode={projects.length > 0 ? "client" : "server"}
        // onFilterModelChange={projects.length > 0 ? undefined : onFilterChange}
        rows={searchResultsWithFav}
        columns={columns}
        components={{
          Row: props.isNarrowWidth ? DataGridCard : undefined,
          // ErrorOverlay,
          LoadingOverlay: LinearProgress,
          Toolbar: GridToolbar,
          NoRowsOverlay: () =>
            CustomNoRowsOverlay(
              searchReturnedNoResult ? (
                <>
                  No results found. Try searching with a different keyword.
                  <br />
                  Only non-confidential and live projects are available.
                </>
              ) : (
                <>
                  Enter some text in the search box above to search for a
                  project. <br />
                  Only non-confidential and live projects are available. <br />
                  <br />
                  Your results will be displayed here.
                </>
              )
            ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        initialState={{
          // columns: {
          //   columnVisibilityModel: {
          //     // Hide columns, the other columns will remain visible
          //     actualJob: false,
          //     grp: false,
          //     cm: false,
          //     accCentre: false,
          //     lastInvDt: false,
          //     cf: false,
          //     wip: false,
          //   },
          // },
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: projects.length > 0, //true
            quickFilterProps: {
              debounceMs: 500,
              placeholder: "Quick filter",
            },
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
            // onAdd: onAddClick,
          },
        }}
        loading={searchInProgress}
        disableSelectionOnClick
        disableDensitySelector
        getRowHeight={() => "auto"}
        sx={{
          fontFamily: "Arial",
          fontSize: "14px",
          color: "#353535",
          // '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "6px",
            "&:focus": {
              outline: "none",
            },
          },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer": {
            height: "40px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButton-root": {
            fontFamily: "Arial",
            fontSize: "14px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
            {
              fontWeight: "bold",
            },
          "&.MuiDataGrid-root .favourite--cell": {
            paddingLeft: "2px",
            paddingRight: "2px",
          },
          "&.MuiDataGrid-root .number--cell": {
            justifyContent: "space-between",
          },
        }}
      />
    </Box>
  );
}

function getColumns(): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: "fav",
      cellClassName: "favourite--cell",
      headerName: "Favourite",
      renderHeader: () => "",
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      minWidth: 15,
      width: 32,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <FavoriteButton
          isFavorite={params.row.fav}
          project={params.row.id}
          tabIndexValue={params.hasFocus ? 0 : -1}
          iconTabIndexValue={-1}
        />
      ),
    },
    {
      field: "id",
      cellClassName: "number--cell",
      headerName: "Number",
      headerAlign: "center",
      minWidth: 124,
      align: "center",
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          <a href={`/project/${params.value}`}>{params.value}</a>
          <SubMenu
            projectNumber={params.value || ""}
            apUrl={
              (params.row.actualJob === 1 || params.row.actualJob === "Billable") && params.row.apUrl
                ? params.row.apUrl
                : ""
            }
            oppGuid={params.row.oppGuid || ""}
            startDt={params.row.startDt}
          />
        </>
      ),
      hideable: false,
    },
    {
      field: "n",
      headerName: "Name",
      minWidth: 180,
      flex: 1.5,
      hideable: false,
    },
    {
      field: "pd",
      headerName: "Project director",
      minWidth: 150,
      //hideable: false,
    },
    {
      field: "pm",
      headerName: "Project manager",
      minWidth: 150,
      // flex: 1,
      //hideable: false,
    },
    {
      field: "cm",
      headerName: "Commercial manager",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "fpc",
      headerName: "FPC",
      // flex: 1,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "pp",
      headerName: "Profit %",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "lastInvDt",
      headerName: "Last invoice date",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "fuPFF",
      headerName: "Last PFF update",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "wip",
      headerName: "Work in progress",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "cf",
      headerName: "Cashflow",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "accCentre",
      headerName: "Accounting centre",
      minWidth: 150,
      flex: 1.25,
    },
    { field: "client", headerName: "Client", minWidth: 120, flex: 1 },
    {
      field: "grp",
      headerName: "Group",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "actualJob",
      headerName: "Billable",
      type: "singleSelect",
      valueOptions: ["Billable", "Non-Billable"],
    },
    {
      field: "ippC",
      headerName: "Contract status",
      minWidth: 150,
      renderCell: addIppIcons,
      type: "singleSelect",
      valueOptions: ["Ok", "Warning", "Critical"],
      filterOperators: [
        ...getGridSingleSelectOperators(),
        {
          value: "isEmpty",
          getApplyFilterFn: () => {
            return ({ value }): boolean => {
              return value === "" || value == null;
            };
          },
          requiresFilterValue: false,
        },
      ],
    },
    // customisable columns
    {
      field: "debt",
      headerName: "Total debts",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
  ];
  return columns;
}
