import { useEffect, useLayoutEffect, useState } from "react";
import { IconButton, LinearProgress, Paper, Typography, useMediaQuery } from "@mui/material";
import { IEmbedConfiguration, models } from "powerbi-client";
import {
  Outlet,
  useNavigate,
  useParams,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ReportPage } from "../config/appConfig";
import powerBiApiService from "../services/powerBiService.api";
import Breadcrumb from "../components/Breadcrumb";
import { getFilter } from "../utils/helpers";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeReportPageState,
  navigateToSubProjectState,
  navigateToProjectState,
  embedConfigurationState,
  reportLoadedState,
  swaAuthenticatedState,
  favouritesState,
  sidebarExpandedState,
  userDataState,
  groupsDataState
} from "../State";
import Sidebar from "../components/Sidebar";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { useQuery } from "@tanstack/react-query";
import useAuthentication from "../hooks/useAuthentication";
import { ProjectsData } from "../types/ProjectsData";
import { UserData } from "../types/UserData";
import SidebarMobile from "../components/SidebarMobile";
import { usePostHog } from 'posthog-js/react';
import { useMsal, useAccount } from "@azure/msal-react";

const Layout = () => {
  const isNarrowWidth = !useMediaQuery("(min-width:600px)");
  if(isNarrowWidth){
    localStorage.setItem("ApexMobileMode", "2");
  }else{
    localStorage.setItem("ApexMobileMode", "");
  }
  const { isAuthenticated } = useAuthentication();
  const [swaAuthenticated, setSwaAuthenticated] = useRecoilState<boolean>(
    swaAuthenticatedState
  );
  const [sidebarExpanded, setSidebarExpanded] = useRecoilState<boolean>(sidebarExpandedState);

  const navigateToSubProject = useRecoilValue<boolean>(
    navigateToSubProjectState
  );
  const navigateToProject = useRecoilValue<boolean>(navigateToProjectState);
  const activeReportPage = useRecoilValue<string>(activeReportPageState);

  const setEmbedConfiguration = useSetRecoilState<
    IEmbedConfiguration | undefined
  >(embedConfigurationState);
  const setUserData = useSetRecoilState<UserData>(userDataState);
  const setGroupData = useSetRecoilState<string[]>(groupsDataState)
  const [error, setError] = useState<any>(null);
  const reportLoaded = useRecoilValue<boolean>(reportLoadedState);
  const { id } = useParams<"id">();
  const navigationType = useNavigationType();
  let location = useLocation();
  const posthog = usePostHog();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log(
    `[Layout render ${navigationType} to ${location.pathname}]id:${id}, P:${navigateToProject} | SUB:${navigateToSubProject}, Report:`,
    window.report
  );

  const navigate = useNavigate();
  const setFavourites = useSetRecoilState<ProjectsData[]>(favouritesState);

  const {
    isLoading: isLoadingSettings,
    error: fetchSettingsError,
    data: fetchedSettingsData,
    isSuccess:isSettingsSuccess,
  } = useQuery({
    queryKey: ["apexSettings"],
    queryFn: () => fetch("/api/me/settings").then((r) => r.json()),
    enabled: !!isAuthenticated,
  });
  const {
    isLoading: isLoadingGroups,
    error: fetchGroupsError,
    data: fetchedGroupsData,
    isSuccess: isGroupsSuccess,
  } = useQuery({
    queryKey: ["apexGroups"],
    queryFn: () => fetch("api/me/groups").then((r) => r.json()),
    enabled: !!isAuthenticated,
  });
  const {
    isLoading:isLoadingUser,
    error: fetchUserError,
    data: fetchedUserData,
    isSuccess: isUserSuccess,
  } = useQuery({
    queryKey: ["apexUser"],
    queryFn: async () => await fetch("/api/me").then((r) => r.json()).then(user => user.data),
    enabled: !!isAuthenticated,
    cacheTime: 1000*60*24*5, // 5 days
    staleTime: 1000*60*24*5
  });

  useEffect(() => {
    console.log(`[Layout useEffect]`);
    if (isAuthenticated) {
      setSwaAuthenticated(true);
    }

    powerBiApiService
      .getEmbedConfiguration()
      .then((cfg) => {
        if (id) {
          const url = location.pathname.replace(/\/$/, ""); //remove trailing / else switch test fails
          if (location.pathname.match("/.*/$")) {
            console.log(
              `[Layout useEffect] url has trailing slash. redirecting to correct url`
            );
            navigate(url, { replace: true });
          }
          const msg = `[Layout useEffect] To load ${url}, adding pageName+filter to config:`;
          switch (url) {
            case `/project/${id}`:
              cfg.pageName = ReportPage.ProjectDetails.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/details`:
              cfg.pageName = ReportPage.Details.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/financial-report`:
              cfg.pageName = ReportPage.FinancialReport.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/job-financial-report`:
              cfg.pageName = ReportPage.JobFinancialReport.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/expenses`:
              cfg.pageName = ReportPage.Expenses.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            // case `/project/${id}/compare-expenses`:
            //   cfg.pageName = ReportPage.CompareExpenses.name;
            //   cfg.filters = getFilter(id);
            //   console.log(msg, cfg);
            //   break;
            case `/project/${id}/invoices`:
              cfg.pageName = ReportPage.Invoices.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/timesheets`:
              cfg.pageName = ReportPage.Timesheets.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/staff-resourcing`:
              cfg.pageName = ReportPage.StaffResourcing.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/hours-and-costs`:
              cfg.pageName = ReportPage.HoursAndCosts.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/site-visit-training-compliance`:
              cfg.pageName = ReportPage.SiteVisitTrainingCompliance.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/unexpected-time-bookings`:
              cfg.pageName = ReportPage.UnexpectedTimeBookings.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/project-team-members`:
              cfg.pageName = ReportPage.ProjectTeamMembers.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/opportunities`:
              cfg.pageName = ReportPage.Opportunities.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            case `/project/${id}/key-dates`:
              cfg.pageName = ReportPage.KeyDates.name;
              cfg.filters = getFilter(id);
              console.log(msg, cfg);
              break;
            default:
              navigate("/page-not-found");
              break;
          }
        } else {
          console.log(`[Layout useEffect] Normal config:`, cfg);
        }

        setEmbedConfiguration(cfg);
      })
      .catch((error: any) => {
        console.log("Error getting EmbedConfiguration from service", error);
        const errorCode = error?.response?.data?.error
          ? JSON.stringify(error?.response?.data?.error)
          : ""; // {"code":"UserNotLicensed","message":"User is not licensed for Power BI"}
        setError({
          message: `There was an error connecting to Power BI. ${
            errorCode ? "Error Details: " + errorCode : ""
          }`,
        });
      });

    return () => {
      console.log(`[Layout useEffect] UNMOUNTING`);
    };
  }, [isAuthenticated,isNarrowWidth]);

  useLayoutEffect(() => {
    if (!isLoadingSettings && fetchedSettingsData && fetchedSettingsData.items) {
      let items:ProjectsData[] = fetchedSettingsData.items.length > 0 ? [...fetchedSettingsData.items] : [];
      items.sort((a: any, b: any ) => (b.id < a.id ? -1 : 1));
      setFavourites(items);
    }
  }, [fetchedSettingsData]);

  useLayoutEffect(() => {
    if (isLoadingGroups && fetchedGroupsData) {
      console.log('[Fetched Groups Data]: ' + JSON.stringify(fetchedGroupsData));
      setGroupData(fetchedGroupsData);
    }
  },[fetchedGroupsData])

  useEffect(()=>{
    if (isUserSuccess) {
      PosthogIdentify(fetchedUserData);
      setUserData(fetchedUserData);
    }
  },[isUserSuccess])

  if (fetchUserError) console.error(fetchUserError);
  if (fetchGroupsError) console.error(fetchGroupsError);

  const PosthogIdentify = (userData:UserData) => {
    console.log('[Fetched User Data]: ' + JSON.stringify(userData));
    if (account && userData.sId) {
      posthog?.identify(userData.sId, {
        email: account.username,
        displayName: account.name,
        discipline: userData.d,
        employment_category: userData.emp,
        grade: userData.g,
        office: userData.l,
        region: userData.re,
        service_portfolio: userData.sp,
        role: userData.ro,
        groupName: userData.gn,
        country: userData.c,
      })
    }
    else{
      posthog?.identify("UnidentifedUser");
      console.log(userData);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // event.preventDefault();
    event.stopPropagation();
    setSidebarExpanded(!sidebarExpanded);
  };

  if (isLoadingSettings || !swaAuthenticated) 
    return <LinearProgress sx={{  height:"3px" }} color="inherit" />;

  if (fetchSettingsError) return <div>An error has occurred: {error.message}</div>;

  const height = "calc(100vh - 56px)"; // vh - appheader

  const onHomeLinkClicked = (
    event: React.MouseEvent<Element, MouseEvent>
  ): void => {
    event.preventDefault();
    window.location.href = "/";
  };

  if (error)
    return (
      <div
        style={{
          height,
        }}
      >
        <Breadcrumb
          projectText={id || ""}
          onHomeLinkClicked={onHomeLinkClicked}
          onProjectTextClick={() => {}}
          projectSubPage=""
        />
        <div
          style={{
            paddingTop: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
            marginLeft: "calc(100vw - 100%)",
            marginRight: "0",
            minWidth: "1200px",
          }}
        >
          <Outlet />
          <Paper
            elevation={4}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "1172px",
              padding: "16px",
              background: "white",
            }}
          >
            <Typography component="div" sx={{ flexGrow: 1, color: "#353535" }}>
              {error.message}
            </Typography>
          </Paper>
        </div>
      </div>
    );

  // Switch to ProjectDetails page
  const onProjectTextClicked = (
    event: React.MouseEvent<Element, MouseEvent>
  ): void => {
    event.preventDefault();
    console.info(
      `[onProjectTextClicked] Changing page to details page. event=`,
      event
    );
    if (window.report) {
      window.report.setPage(ReportPage.ProjectDetails.name);
      navigate(`/project/${id}`);
    }
  };

  return (
    <main className="main">
      {isNarrowWidth && (
        <SidebarMobile
          project={id || ""}
          disabled={!reportLoaded || !swaAuthenticated || isLoadingSettings}
          region={fetchedUserData?.r || ""}
        />
      )}

      {!isNarrowWidth && (
        <Paper
          className="left"
          elevation={5}
          sx={{
            minHeight: "100%",
            maxWidth: 264,
            bgcolor: "white",
            borderRadius: "inherit",
            ...(!sidebarExpanded && {
              width: "48px !Important",
            }),
          }}
        >
          <div
            style={{
              height: "calc(100% - 48px)",
              overflowY: "auto",
            }}
          >
            <Sidebar
              expanded={sidebarExpanded}
              project={id || ""}
              disabled={!reportLoaded || !swaAuthenticated || isLoadingSettings}
              region={fetchedUserData?.r || ""}
            />
          </div>
          <div
            style={{
              height: "48px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <IconButton
              sx={{ height: "100%", borderRadius: 0, padding: 0 }}
              onClick={handleClick}
            >
              <KeyboardDoubleArrowRightIcon
                fontSize="large"
                sx={{
                  color: "#9B9B9B" /* arc-grey/050 */,
                  ...(sidebarExpanded && { transform: "rotate(-180deg)" }),
                  width: "48px",
                }}
              />
            </IconButton>
          </div>
        </Paper>
      )}

      <div className="middle">
        <div className="full-bleed">
          <Breadcrumb
            projectText={id || ""}
            onHomeLinkClicked={onHomeLinkClicked}
            onProjectTextClick={onProjectTextClicked}
            projectSubPage={
              activeReportPage === "LandingPage" ||
              activeReportPage === "ProjectDetails"
                ? ""
                : activeReportPage
            }
          />
        </div>
        {isNarrowWidth && <Outlet />}
        {!isNarrowWidth && (
          <div
            className="middle-content-centered"
            style={{
              paddingTop: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Outlet />
          </div>
        )}
      </div>
    </main>
  );
};

export default Layout;
