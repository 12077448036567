import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { IEmbedConfiguration } from "powerbi-client";
import { atom, selector } from "recoil";
import { ReportPage } from "./config/appConfig";
import { ProjectsData } from "./types/ProjectsData";
import { UserData } from "./types/UserData";

// Set by Power BI report loaded event handler. Sidebar navigation links are enabled once report has loaded 
export const reportLoadedState = atom<boolean>({
  key: "reportLoadedState",
  default: false,
});

// Can only be set by Layout
export const reportReadyState = atom<boolean>({
  key: "reportReadyState",
  default: false,
});

// Can be set by Layout onDataSelected, and tbc
export const selectedProjectState = atom<string>({
  key: "projectIdState",
  default: "",
});

export const embedConfigurationState = atom<IEmbedConfiguration | undefined>({
  key: "embedConfigurationState",
  default: undefined,
});

export const activeReportPageState = atom<string>({
  key: "activeReportPageState",
  default: "",
});

export const navigateToSubProjectState = atom<boolean>({
  key: "navigateToSubProjectState",
  default: false,
});

export const swaAuthenticatedState = atom<boolean>({
  key: "swaAuthenticated",
  default: false,
});

export const navigateToProjectState = atom<boolean>({
  key: "navigateToProjectState",
  default: true,
});

export const yourProjectsState = atom<ProjectsData[]>({
  key: "yourProjects",
  default: [],
});

export const yourGroupProjectsState = atom<ProjectsData[]>({
  key: "yourGroupProjects",
  default: [],
})

export const yourProjectsErrorMessageState = atom<string>({
  key: "yourProjectsErrorMessage",
  default: '',
});

export const groupsErrorMessageState = atom<string>({
  key: "groupsErrorMessage",
  default: '',
});

export const favouritesState = atom<ProjectsData[]>({
  key: "favourites",
  default: [],
});

export const favouritesErrorMessageState = atom<string>({
  key: "favouritesErrorMessage",
  default: '',
});

export const yourProjectsWithFavState = selector<ProjectsData[]>({
  key: "YourProjectsWithFav",
  get: ({get}) => {
    const favList = get(favouritesState); // data from /api/me/settings
    const yourProjects = get(yourProjectsState); // data from api/me/projects

    return yourProjects.map((project) => {
      const fav = favList.some((f) => f.id === project.id);
      return {...project, fav};
    });
  },
  set: ({set}, newValue) => set(yourProjectsState, newValue),
});

export const yourGroupProjectsWithFavState = selector<ProjectsData[]>({
  key: "YourGroupProjectsWithFav",
  get: ({get}) => {
    const favList = get(favouritesState); // data from /api/me/settings
    const yourGroupProjects = get(yourGroupProjectsState); // data from api/me/groupprojects

    return yourGroupProjects.map((project) => {
      const fav = favList.some((f) => f.id === project.id);
      return {...project, fav};
    });
  },
  set: ({set}, newValue) => set(yourGroupProjectsState, newValue),
});

export const groupsDataState = atom<string[]>({
  key: "GroupsState",
  default: []
});

export const groupsFilterState = atom<string>({
  key: "GroupTabFilterState",
  default: ''
});

export const searchResultsWithFavState = selector<ProjectsData[]>({
  key: "SearchResultsWithFav",
  get: ({get}) => {
    const favList = get(favouritesState); // data from /api/me/settings
    const searchResults = get(searchResultsState); // data from api/projects?k=""

    return searchResults.map((project) => {
      const fav = favList.some((f) => f.id === project.id);
      return {...project, fav};
    });
  },
  set: ({set}, newValue) => set(searchResultsState, newValue),
});

export const searchResultsState = atom<ProjectsData[]>({
  key: "searchResults",
  default: [],
});

export const selectedTabState = atom<number>({
  key: "selectedTab",
  default: 0,
});

export const searchInProgressState = atom<boolean>({
  key: "searchInProgress",
  default: false,
});

export const searchReturnedNoResultState = atom<boolean>({
  key: "searchReturnedNoResult",
  default: false,
});

const localStorageEffect = (key:string) => ({setSelf, onSet}:any) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue:any, _:any, isReset:any) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const columnVisibilityModelState = atom<GridColumnVisibilityModel>({
  key: "columnVisibilityModelState",
  default: {
    grpc: false,
    grp: true,
    cm: false,
    accCentre: false,
    lastInvDt: false,
    cf: false,
    wip: false,
    debt: true,
    startDt: false
  },
  effects: [
    localStorageEffect('apex_columnpreferences'),
  ]
});

export const sidebarExpandedState = atom<boolean>({
  key: "sidebarExpanded",
  default: true,
  effects: [
    localStorageEffect('apex_sidebar'),
  ]
});

export const userPhotoState = atom<string>({
  key: "userPhoto",
  default: "",
});

export const userDataState = atom<UserData>({
  key: "userDataState",
  default: {
    d: "",
    emp: "",
    g: "",
    l: "",
    r: "",
    c: "",
    sp: "",
    ro: "",
    re: "",
    gn: "",
    gc: "",
    sId: "",
  }
});

