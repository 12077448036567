import { useEffect, useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DocumentHead } from "../common/DocumentHead";
import { ReportPage } from "../config/appConfig";
import { navigateToProjectState, navigateToSubProjectState } from "../State";

const ProjectSubPage = ({ title }: any) => {
  const [navigateToProject, setNavigateToProject] = useRecoilState<boolean>(navigateToProjectState);
  const [navigateToSubProject, setNavigateToSubPage] = useRecoilState<boolean>(navigateToSubProjectState);

  let location = useLocation();

  const { id } = useParams<"id">();

  console.log(`[ProjectSubPage render  ${location.pathname}] P:${navigateToProject} | SUB:${navigateToSubProject}`);

  useLayoutEffect(()=>{
    console.log(`[ProjectSubPage useLayoutEffect  ${location.pathname}] P:${navigateToProject} | SUB:${navigateToSubProject}`);
    setNavigateToSubPage(false);
    setNavigateToProject(true);

    return () => {
      console.log(`[ProjectSubPage useLayoutEffect  ${location.pathname}] return block`);
    };
  });

  useEffect(() => {
    console.log(`[ProjectSubPage useEffect  ${location.pathname}] P:${navigateToProject} | SUB:${navigateToSubProject}`);
    setNavigateToSubPage(false);
    setNavigateToProject(true);
    const url = `/project/${id}/${title.toLowerCase().replace(/\s+/g, "-")}`;
    if (window.report && location.pathname === url) {
      const init = async () => {
        setNavigateToProject(false);
        
        const activePage = (await window.report.getActivePage()).displayName;
        
        if (title !== activePage) {
          switch (title) {
            case ReportPage.Details.displayName:
              await window.report.setPage(ReportPage.Details.name);
              break;            
            case ReportPage.FinancialReport.displayName:
              await window.report.setPage(ReportPage.FinancialReport.name);
              break;
            case ReportPage.JobFinancialReport.displayName:
              await window.report.setPage(ReportPage.JobFinancialReport.name);
              break;     
            case ReportPage.Expenses.displayName:
              await window.report.setPage(ReportPage.Expenses.name);
              break;
            // case ReportPage.CompareExpenses.displayName:
            //   await window.report.setPage(ReportPage.CompareExpenses.name);
            //   break;
            case ReportPage.Invoices.displayName:
              await window.report.setPage(ReportPage.Invoices.name);
              break;
            case ReportPage.Timesheets.displayName:
              await window.report.setPage(ReportPage.Timesheets.name);
              break;
            case ReportPage.StaffResourcing.displayName:
              await window.report.setPage(ReportPage.StaffResourcing.name);
              break;
            case ReportPage.HoursAndCosts.displayName:
              await window.report.setPage(ReportPage.HoursAndCosts.name);
              break;
            case ReportPage.SiteVisitTrainingCompliance.displayName:
              await window.report.setPage(ReportPage.SiteVisitTrainingCompliance.name);
              break;
            case ReportPage.UnexpectedTimeBookings.displayName:
              await window.report.setPage(ReportPage.UnexpectedTimeBookings.name);
              break;
            case ReportPage.ProjectTeamMembers.displayName:
              await window.report.setPage(ReportPage.ProjectTeamMembers.name);
              break;                         
            case ReportPage.Opportunities.displayName:
              await window.report.setPage(ReportPage.Opportunities.name);
              break;
            case ReportPage.KeyDates.displayName:
              await window.report.setPage(ReportPage.KeyDates.name);
              break;  
          }
        }

        console.log(
          `[ProjectSubPage useLayoutEffect ${location.pathname}] activePage:`, activePage
        );
      };

      init();
    }

    return () => {
      console.log(`[ProjectSubPage useEffect  ${location.pathname}] Back button used? unmounting`);
    };
  }, [location.pathname]);

  return (
      <DocumentHead title={`${id} : ${title}`} projectNumber={`${id}`} subPageTitle={`${title}`} />
  );
};

export default ProjectSubPage;
