import * as React from "react";
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    // maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[3],
  },
  // .MuiTooltip-arrow::before 
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    border: "1px solid rgba(0,0,0,0.4)",
    boxShadow: theme.shadows[1],
  },
}));
