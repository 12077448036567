import * as React from "react";
import { Drawer, IconButton, Fab } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Sidebar from "./Sidebar";

const drawerWidth = 240;

interface Props {
  project: string;
  disabled?: boolean;
  region: string
}

export default function SidebarMobile(props: Props) {
  const [mobileSidebarOpen, setmobileSidebarOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setmobileSidebarOpen(!mobileSidebarOpen);
  };

  const handleLinkClick = (link: any) => {
    // console.log(`Clicked on link: ${link}`);
    setmobileSidebarOpen(false);
  }

  return (
    <>
      <Drawer
        open={mobileSidebarOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <div
          style={{
            height: "calc(100% - 48px)",
            overflowY: "auto",
          }}
        >
          <Sidebar
            onLinkClick={handleLinkClick}
            expanded={true}
            project={props.project}
            disabled={props.disabled}
            region={props.region}
          />
        </div>
        {mobileSidebarOpen && (
          <div
            style={{
              height: "48px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <IconButton
              sx={{ height: "100%", borderRadius: 0, padding: 0 }}
              onClick={handleDrawerToggle}
            >
              <KeyboardDoubleArrowLeftIcon
                fontSize="large"
                sx={{
                  color: "#9B9B9B",
                  width: "48px",
                }}
              />
            </IconButton>
          </div>
        )}
      </Drawer>
      {!mobileSidebarOpen && (
        <Fab
          color="inherit"
          aria-label="open drawer"
          size="small"
          onClick={handleDrawerToggle}
          style={{ bottom: 5, position: "absolute", borderRadius: 2 }}
        >
          <KeyboardDoubleArrowRightIcon
            fontSize="large"
            sx={{
              color: "#9B9B9B",
            }}
          />
        </Fab>
      )}
    </>
  );
}
