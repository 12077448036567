import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthentication = () => {
    const [auth, setAuth] = useState(null);
    // const navigate = useNavigate();

    useEffect(() => {
        async function getUserInfo() {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            if (payload.clientPrincipal) {
                console.log(`[useAuthentication] payload.clientPrincipal: ${payload.clientPrincipal} `);
                
                return payload.clientPrincipal;
            }

            console.log(`[useAuthentication] payload.clientPrincipal is null. Redirecting to /.auth/login/aad`);
            // navigate(`/login`, {replace:true});
            // window.history.replaceState(null, "Login", "/.auth/login/aad?post_login_redirect_uri=/")
            // window.location.replace("/.auth/login/aad?post_login_redirect_uri=/");
            window.location.replace(`/login?post_login_redirect_uri=${window.location.href}`);
        }

        getUserInfo().then(u => {
            console.log(`SWA clientPrincipal:`, u);
            setAuth(u)
        }
        )
    }, []);

    return { account: auth, isAuthenticated: !!auth };
}

export default useAuthentication