import { Box } from "@mui/material";
import BannerImage from "../images/BannerImageSmall.png";
import SearchBox from "./SearchBox";

export function Banner(props: { isNarrowWidth: boolean; }) {
  console.log(`[Banner]isNarrowWidth:${props.isNarrowWidth}`);
  
  return (
    <Box
      className="banner"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: "8px 16px 0px",
        ...(!props.isNarrowWidth && {
          padding: "16px 12px 16px 16px",
          flexShrink: 0,
          width: "1172px",
          height: "140px",
          overflow: "hidden",
          backgroundImage: `url(${BannerImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "50% 81%",
          gap: "16px",
        }),
      }}
    >
      {!props.isNarrowWidth && (
        <div
          className="title"
          style={{
            fontSize: "34px",
            letterSpacing: "0.25px",
            fontWeight: "normal",
            fontFamily: "Times New Roman,serif",
            color: "#424242",
            height: "34px",
            lineHeight: "34px",
          }}
        >
          Welcome to Apex
        </div>
      )}
      <SearchBox className="searchbox" />
    </Box>
  );
}
