import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useRecoilValue } from 'recoil';
import { userDataState } from '../State';
import { UserData } from '../types/UserData';
import posthog from 'posthog-js'

export function DocumentHead(props: { title: string; projectNumber: string; subPageTitle: string;}) {
  const userData = useRecoilValue<UserData>(userDataState);
  const location = useLocation();
  useEffect(() => {
    if (userData.d || userData.emp || userData.g || userData.l || userData.re || userData.sp) {
      console.log(`[DocumentHead] ${props.title}|${location.pathname}|document.title:${document.title} | document.referrer:${document.referrer}`, userData);
      posthog.capture('ApexPageview', {
        page_title: props.title,
        project_number: props.projectNumber,
        subpage_title: props.subPageTitle
      });
      const tagManagerArgs = {
        gtmId: "GTM-KPF2RLX",
        events: {
          page_view: "page_view",
        },
      };
      TagManager.initialize(tagManagerArgs);
      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view', // event name declared during initialization
          page_title: props.title,
          discipline: userData.d || "No Discipline Data", //"Digital Technology",
          employment_category: userData.emp || "No Category Data", //"Permanent Full-Time",
          grade: userData.g || "No Grade Data", // "GRD6",
          office: userData.l || "No Office Data", //"London Office",
          region: userData.re || "No Region Data", //"UKIMEA Region",
          service_portfolio: userData.sp || "No Portfolio Data", //"Key Services/Unflagged",
          role: userData.ro,
          groupName: userData.gn, //Digital Technology
        }
      });
      return () => {
        console.log(`[DocumentHead] Unmounting ${props.title}|${location.pathname}|document.title:${document.title}`);
      };
    }
  }, [location.pathname]);
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={`${props.title} component`} />
    </Helmet>
  );
}
