import { models, Report } from "powerbi-client";
import { EmbedProps } from "../components/PowerBIEmbed";
import { Link } from "../types/Link";

/**
 * Get JSON string representation of the given map.
 * 
 * @param map Map of event and corresponding handler method
 * 
 * For example:
 * Input:
 * ```
 *  Map([
		['loaded', null],
		['rendered', function () { console.log('Rendered'); }]
	]);
 * ```
 * Output:
 * ```
 * `[["loaded",""],["rendered","function () { console.log('Rendered'); }"]]`
 * ```
 */
export function stringifyMap(map: EmbedProps['eventHandlers']): string {

	// Return empty string for empty/null map
	if (!map) {
		return '';
	}

	// Get entries of map as array
	const mapEntries = Array.from(map);
	
	// Return JSON string
	return JSON.stringify(mapEntries.map((mapEntry) => {
		
		// Convert event handler method to a string containing its source code for comparison
		return [
			mapEntry[0], 
			mapEntry[1] ? mapEntry[1].toString() : ''
		]; 
	}));
};

export function getLayout(): models.LayoutType.MobilePortrait | undefined {
  const mobileMode = localStorage.getItem("ApexMobileMode");
  if (mobileMode === "2")
    return models.LayoutType.MobilePortrait;
  return undefined;
}

export const getFilter = (
    selectedProject: string = ""
  ): models.ReportLevelFilters[] => {
    console.log(
      `[getFilter] constructing filters for Project Number=${selectedProject}`
    );
    const filters: models.ReportLevelFilters[] = [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: models.FilterType.Basic,
        operator: "In",
        requireSingleSelection: true,
        target: {
          table: "Project",
          column: "ProjectNumber",
        },
        values: [selectedProject],
      },
    ];
    return filters;
  };

  export function isValidTextAndCharLength(text: string | undefined, length:number): boolean {
    if (!text) return false;
      //const regex = /^[A-Za-z0-9 -]+$/;
      const trimmed = text.trim();
    //return regex.test(trimmed) && trimmed.length >= length;
    return trimmed.length >= length;
  }

  declare global {
    interface Window {
      report: Report;
      apexGroups: any;
      apexErrorObject: any;
    }
  }

  export function getRegionMatchedLink(
    userRegionName: string,
    linkType: string
  ): Link | undefined {
    switch (userRegionName) {
      case "Americas Region":
        return {
          label: `${linkType} - Americas`,
          url:
            linkType === "AMS"
              ? "https://arup.sharepoint.com/sites/Essentials-Americas/ams/Pages/default.aspx"
              : (linkType === "Legal" ? "https://arup.sharepoint.com/sites/essentials-americas-legal" : "https://arup.sharepoint.com/sites/essentials-americas-finance"),
        };
      case "Australasia Region":
        return {
          label: `${linkType} - Australasia`,
          url:
            linkType === "AMS"
              ? "https://arup.sharepoint.com/sites/essentials-australasia-ams"
              : (linkType === "Legal" ? "https://arup.sharepoint.com/sites/essentials-australasia-legal" : "https://arup.sharepoint.com/sites/essentials-australasia-finance"),
        };
      case "East Asia Region":
        return {
          label: `${linkType} - East Asia`,
          url:
            linkType === "AMS"
              ? "https://arup.sharepoint.com/sites/essentials-eastasia/ams"
              : (linkType === "Legal" ? "https://arup.sharepoint.com/sites/Essentials-EastAsia/legal/Pages/Legal.aspx" : "https://arup.sharepoint.com/sites/Essentials-EastAsia/finance/Pages/finance.aspx"),
        };
      case "Europe":
        return {
          label: `${linkType} - Europe`,
          url:
            linkType === "AMS"
              ? "https://arup.sharepoint.com/sites/essentials-europe-ams"
              : (linkType === "Legal" ? "https://arup.sharepoint.com/sites/essentials-europe-legal" : "https://arup.sharepoint.com/sites/essentials-europe-finance"),
        };
      case "UKIMEA Region":
        return {
          label:
            linkType === "AMS"
              ? `${linkType} (HSEQ) - UKIMEA`
              : `${linkType} - UKIMEA`, //AMS (HSEQ) - UKIMEA
          url:
            linkType === "AMS"
              ? "https://arup.sharepoint.com/sites/essentials-ukimea-ams"
              : (linkType === "Legal" ? "https://arup.sharepoint.com/sites/essentials-ukimea-legal" : "https://arup.sharepoint.com/sites/essentials-ukimea-finance"),
        };
      // default:
      //   return {label: "", url: ""};
    }
  }