import * as React from "react";
import {
  Avatar,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridToolbar,
  GridRenderCellParams,
  GridValueFormatterParams,
  getGridSingleSelectOperators,
  GridColumnVisibilityModel,
  GridFilterItem,
  GridCellParams
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo } from "react";
import { ProjectsData } from "../types/ProjectsData";
import { NavLink } from "react-router-dom";
import {
  addIppIcons,
  addPadLockIfNoAccess,
  appendPercentSymbol,
  monthNames,
  numberFormatter,
} from "../utils/dataGridHelpers";
import { DataGridCard } from "./DataGridCard";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  columnVisibilityModelState,
  userPhotoState,
  reportReadyState,
  yourProjectsErrorMessageState,
  yourGroupProjectsState,
  yourGroupProjectsWithFavState,
  groupsDataState,
  groupsErrorMessageState,
  groupsFilterState,
  userDataState
} from "../State";
import { FavoriteButton } from "../common/FavoriteButton";
import { CustomErrorOverlay, CustomNoRowsOverlay, CustomLinearProgressWithText } from "../common/DataGridOverlays";
import SubMenu from "../common/SubMenu";
import { useAccount, useMsal } from "@azure/msal-react";
import NameAvatar from "./NameAvatar";
import { UserData } from "../types/UserData";
import { JSONResponse } from "../types/JSONResponse";

export default function GroupProjects(props: { isNarrowWidth?: boolean }) {
  const [loading, setLoading] = React.useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] =
  useRecoilState<GridColumnVisibilityModel>(columnVisibilityModelState);
  const [yourGroupProjects, setYourGroupProjects] =
  useRecoilState<ProjectsData[]>(yourGroupProjectsState);
  const [groups, setGroups] = useRecoilState<string[]>(groupsDataState);
  const [groupsErrorMessage, setGroupsErrorMessage] = useRecoilState<string>(groupsErrorMessageState);
  const yourGroupProjectsWithFav = useRecoilValue<ProjectsData[]>(
    yourGroupProjectsWithFavState
  );
  const [groupsFilter, setGroupsFilter] = useRecoilState<string>(groupsFilterState);
  const reportReady = useRecoilValue<boolean>(reportReadyState);
  const [yourProjectsErrorMessage, setYourProjectsErrorMessage] =
  useRecoilState<string>(yourProjectsErrorMessageState);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const userPhoto = useRecoilValue<string>(userPhotoState);
  const currentUser = localStorage.getItem("apex_mockUser") || account?.name; //"John Read";
  const userData = useRecoilValue<UserData>(userDataState);
  const groupsData = useRecoilValue<string[]>(groupsDataState);

  const getData = useCallback(async (model:any) => {
    setLoading(true);

    await window
      .fetch('api/me/groups')
      .then((r) => r.json())
      .then((response) => {
        const arr = response.items as Array<any>;
        //console.log('response items: ' + arr.map(e => e.groupName));
        setGroups(arr);
      })
      .catch((e) => {
        setLoading(false);

        console.error(`Error retrieving your groups. Details: ${e.message}`);
        setLoading(false);
        setGroupsErrorMessage(
          `Failed to get your groups from the server. ${e.message}`
        );
      });

      /* try {
        const response = await fetch(`/api/projects?k=${model.items[0].value}`);
  
        const { items, error }: JSONResponse = await response.json();
        if (response.ok) {
          if (items && items.length > 0) {
            // rename actualJob values to Billable or Non-Billable
            const processActualJob = items.map((i) => {
              i.actualJob = i.actualJob ? "Billable" : "Non-Billable";
              return i;
            });
  
            setYourGroupProjects(processActualJob);
            setLoading(false);
            return;
          }
          setYourGroupProjects([]);
          setLoading(false);
          return;
        }
  
        if (error) console.error(error);
      } catch (error) {
        console.log(error);
      } */

    window
      .fetch(`/api/me/groupprojects?k=${userData.gc}`)
      .then((r) => r.json())
      .then((response) => {
        if (response && response.items && !response.error) {
          const arr = response.items as Array<any>;
          const pdAndPmItems = arr.sort((a,b)=>b.id - a.id);
          const pdOnlyItems = arr.sort((a,b)=>b.id - a.id);
          const pmOnlyItems = arr.sort((a,b)=>b.id - a.id);
          const notPmOrPDItems = arr.sort((a,b)=>b.id - a.id);
          const sortedByGroups = [...pdAndPmItems, ...pdOnlyItems, ...pmOnlyItems,...notPmOrPDItems];

          setYourGroupProjects(sortedByGroups);
          setYourProjectsErrorMessage("");
        } else {
          setYourProjectsErrorMessage(
            `${
              response.error
                ? response.error
                : "Failed to get your group projects from the server"
            }`
          );
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        console.error(`Error retreiving your projects. Details: ${e.message}`);
        setLoading(false);
        setYourProjectsErrorMessage(
          `Failed to get your group projects from the server. ${e.message}`
        );
      });
  }, []);

  useEffect(() => {
    if (yourGroupProjects.length !== 0) return;

    let isMounted = true;
    console.log(`[GroupProjects useEffect] Calling API`);
    getData(isMounted);

    return () => {
      // isMounted = false;
      console.log(`[GroupProjects useEffect unmounting]`);
    };
  }, [getData, account]);

  const columns = useMemo(()=>getColumns(groups, reportReady, currentUser, userPhoto),[groupsData, reportReady, currentUser, userPhoto]);

  const fetchNewData = useCallback(async (model:any) => {
    setLoading(true);

    window
      .fetch(`/api/me/groupprojects?k=${model.items[0].value}`)
      .then((r) => r.json())
      .then((response) => {
        if (response && response.items && !response.error) {
          const arr = response.items as Array<any>;
          const pdAndPmItems = arr.sort((a,b)=>b.id - a.id);
          const pdOnlyItems = arr.sort((a,b)=>b.id - a.id);
          const pmOnlyItems = arr.sort((a,b)=>b.id - a.id);
          const notPmOrPDItems = arr.sort((a,b)=>b.id - a.id);
          const sortedByGroups = [...pdAndPmItems, ...pdOnlyItems, ...pmOnlyItems,...notPmOrPDItems];

          setYourGroupProjects(sortedByGroups);
          setYourProjectsErrorMessage("");
        } else {
          setYourProjectsErrorMessage(
            `${
              response.error
                ? response.error
                : "Failed to get your group projects from the server"
            }`
          );
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        console.error(`Error retreiving your projects. Details: ${e.message}`);
        setLoading(false);
        setYourProjectsErrorMessage(
          `Failed to get your group projects from the server. ${e.message}`
        );
      });
  }, []);
  
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <DataGrid
        disableVirtualization={props.isNarrowWidth ? true : false}
        headerHeight={props.isNarrowWidth ? 0 : 56}
        autoPageSize={props.isNarrowWidth ? true : false}
        // className="dataGrid"
        // disableExtendRowFullWidth={true}
        error={yourProjectsErrorMessage ? true : undefined}
        // error={true}
        rows={yourGroupProjectsWithFav}
        columns={columns}
        components={{
          Row: props.isNarrowWidth ? DataGridCard : undefined,
          LoadingOverlay: () => CustomLinearProgressWithText(
            <>
              Loading, please wait...<br></br>For large Groups, this may take up to 30 seconds...
            </>
          ),
          NoRowsOverlay: () =>
            CustomNoRowsOverlay(
              <>
                By default, this tab is filtered to only show live projects with
                an external client that are related to the group selected through the filtering. 
                If you can't find the project(s) you are looking for here, please search
                for it in the Search results tab
              </>
            ),
          Toolbar: GridToolbar,
          ErrorOverlay: () =>
            CustomErrorOverlay(
              "Please reload the page or try again later. If the issues continues, please raise a ServiceNow incident for support.",
              yourProjectsErrorMessage
            ),
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
        }}
        onFilterModelChange={(model) => {
          fetchNewData(model);
          setGroupsFilter(model.items[0].value)
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [{ columnField: 'grpc', operatorValue: 'is', value: groupsFilter ? groupsFilter : userData.gc as string }],  
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, placeholder: "Quick filter" },
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
          },
        }}
        loading={loading}
        disableSelectionOnClick
        disableDensitySelector
        getRowHeight={() => "auto"}
        sx={{
          fontFamily: "Arial",
          fontSize: "14px",
          color: "#353535",
          // '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "6px",
            "&:focus": {
              outline: "none",
            },
          },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer": {
            height: "40px",
          },
          // '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          "&.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButton-root": {
            fontFamily: "Arial",
            fontSize: "14px",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
            {
              fontWeight: "bold",
            },
          "&.MuiDataGrid-root .favourite--cell": {
            paddingLeft: "2px",
            paddingRight: "2px",
          },
          "&.MuiDataGrid-root .pmPd--cell": {
            gap: "4px",
          },
          "&.MuiDataGrid-root .number--cell": {
            justifyContent: "space-between",
          },
        }}
      />
    </Box>
  );
}

function getColumns(groups: any, reportReady: boolean, currentUser: string | undefined, photo: string): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: "fav",
      cellClassName: "favourite--cell",
      headerName: "Favourite",
      renderHeader: () => "",
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      minWidth: 15,
      width: 32,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <FavoriteButton
          isFavorite={params.row.fav}
          project={params.row.id}
          tabIndexValue={params.hasFocus ? 0 : -1}
          iconTabIndexValue={-1}
        />
      ),
    },
    {
      field: "id",
      cellClassName: "number--cell",
      headerName: "Number",
      headerAlign: "center",
      filterable: false,
      minWidth: 124,
      align: "center",
      renderCell: (params: GridRenderCellParams<string>) => {
        return reportReady ? (
          <NavLink
            to={`/project/${params.value}`}
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params.value}
          </NavLink>
        ) : (
          <>
            <a href={`/project/${params.value}`}>{params.value}</a>
            <SubMenu
              projectNumber={params.value || ""}
              apUrl={params.row.apUrl || ""}
              oppGuid={params.row.oppGuid || ""}
              startDt={params.row.startDt}
            />
          </>
        );
      },
      hideable: false,
    },
    {
      field: "n",
      headerName: "Name",
      filterable: false,
      minWidth: 180,
      flex: 1.5,
      hideable: false,
    },
    {
      field: "pd",
      cellClassName: "pmPd--cell",
      filterable: false,
      headerName: "Project director",
      minWidth: 150,
      flex: 1,
      // hideable: false,
      // headerClassName:"hidden-header",
      // renderHeader: (params: GridColumnHeaderParams) => null,
      renderCell: (params: GridRenderCellParams<string>) => currentUser === params.row.pd? getNameWithPhoto(photo, params.row.pd): undefined,
    },
    {
      field: "pm",
      cellClassName: "pmPd--cell",
      filterable: false,
      headerName: "Project manager",
      minWidth: 150,
      flex: 1,
      // hideable: false,
      renderCell: (params: GridRenderCellParams<string>) => currentUser === params.row.pm? getNameWithPhoto(photo, params.row.pm):undefined,
    },
    {
      field: "cm",
      filterable: false,
      headerName: "Commercial manager",
      minWidth: 150,
      // flex: 1
    },
    {
      field: "fpc",
      filterable: false,
      headerName: "FPC",
      // description :"FPC or Percent complete",
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "pp",
      filterable: false,
      headerName: "Profit %",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: appendPercentSymbol,
    },
    {
      field: "lastInvDt",
      filterable: false,
      headerName: "Last invoice date",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "fuPFF",
      filterable: false,
      headerName: "Last PFF update",
      // flex: 1,
      type: "date",
      minWidth: 110,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) return "";

        const padTo2Digits = (num: number) => num.toString().padStart(2, "0");
        const formatDate = (date: Date) => {
          return [
            padTo2Digits(date.getDate()),
            monthNames[date.getMonth()],
            date.getFullYear(),
          ].join(" ");
        };

        return `${formatDate(new Date(params.value))}`;
      },
      align: "right"
    },
    {
      field: "wip",
      filterable: false,
      headerName: "Work in progress",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "cf",
      filterable: false,
      headerName: "Cashflow",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
    {
      field: "accCentre",
      filterable: false,
      headerName: "Accounting centre",
      minWidth: 150,
      flex: 1.25,
    },
    { 
      field: "client",
      filterable: false,
      headerName: "Client",
      minWidth: 120,
      flex: 1 
    },
    {
      field: "grpc",
      headerName: "Group",
      minWidth: 50,
      flex: 1,
      type: "singleSelect",
      valueOptions: groups.map((e:any) => {
        return {value: e.groupCode, label: e.groupName}
      }),
      filterOperators: getGridSingleSelectOperators().filter(a => a.value === "is").map((operator) => {
        const newOperator = { ...operator }
        const newGetApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
          return (params: GridCellParams): boolean => {
            let isOk = true
            isOk = filterItem?.value == params.value
            return isOk
          }
      }
      newOperator.getApplyFilterFn = newGetApplyFilterFn
      return newOperator})
    },
    {
      field: "grp",
      headerName: "Group",
      minWidth: 150,
      filterable: false,
      flex: 1
    },
    {
      field: "ippC",
      filterable: false,
      headerName: "Contract status",
      minWidth: 150,
      renderCell: addIppIcons,
      type: "singleSelect",
      valueOptions: ["Ok", "Warning", "Critical"],
      filterOperators: [
        ...getGridSingleSelectOperators(),
        {
          value: "isEmpty",
          getApplyFilterFn: () => {
            return ({ value }): boolean => {
              return value === "" || value == null;
            };
          },
          requiresFilterValue: false,
        },
      ],
    },
    // customisable columns
    {
      field: "debt",
      headerName: "Total debts",
      // flex: 1,
      renderCell: addPadLockIfNoAccess,
      valueFormatter: ({ value }) => numberFormatter.format(value),
      align: "right"
    },
  ];
  return columns;
}
function getNameWithPhoto(photo: string, name: string): React.ReactNode {
  return <>
    {photo ? (
      <Avatar
        alt={name}
        src={photo}
        sx={{
          height: "24px",
          width: "24px",
        }} />
    ) : (
      <NameAvatar name={name} />
    )}
    {name}
  </>;
}

