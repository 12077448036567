import axios from "axios";
import { PCA, AquireAccessToken } from "./authenticate";

const client = axios.create({
  baseURL: "https://graph.microsoft.com/v1.0",
  headers: {},
});

// Request interceptor for API calls
client.interceptors.request.use(
  async (config) => {
    const token = await AquireAccessToken(PCA, ["profile", "user.read"]);
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      'ConsistencyLevel': 'eventual',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

class msGraphService {
  getCurrentUserProfilePicture = () => {
    return client.get("/me/photos/48x48/$value", {
      responseType: "blob",
      timeout: 30000,
    });
  };

  getDirectoryMembership = (name: string) => {
    return client.get(
      `/me/transitiveMemberOf/microsoft.graph.group?$count=true&$select=id,displayName&$filter=(displayName eq '${name}')`);
  };
}

export default new msGraphService();
