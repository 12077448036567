import React, { useCallback, useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";

import { appConfig } from "../config/appConfig";
import { ProjectsData } from "../types/ProjectsData";
import { useSetRecoilState } from "recoil";
import {
  searchInProgressState,
  searchResultsState,
  searchReturnedNoResultState,
  selectedTabState,
} from "../State";
import { JSONResponse } from "../types/JSONResponse";
import { isValidTextAndCharLength } from "../utils/helpers";

interface ISearchBoxProps {
  term?: string;
  isOpen?: boolean;
  className?:string;
}

const SearchBox: React.FunctionComponent<ISearchBoxProps> = (props) => {
  const setProjects = useSetRecoilState<ProjectsData[]>(searchResultsState);
  const setSelectedTab = useSetRecoilState<number>(selectedTabState);
  const setSearchInProgress = useSetRecoilState<boolean>(searchInProgressState);
  const setSearchReturnedNoResult = useSetRecoilState<boolean>(
    searchReturnedNoResultState
  );

  const minSearchLength = appConfig.Search.minSearchLength;
  const [localTerm, setLocalTerm] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getData = useCallback(async (searchText:string) => {
    setSelectedTab(3);
    setSearchInProgress(true);
    try {
      const response = await fetch(`/api/projects?k=${searchText}`);

      const { items, error }: JSONResponse = await response.json();
      if (response.ok) {
        if (items && items.length > 0) {
          // rename actualJob values to Billable or Non-Billable
          const processActualJob = items.map((i) => {
            i.actualJob = i.actualJob ? "Billable" : "Non-Billable";
            return i;
          });

          setSearchReturnedNoResult(false);
          setProjects(processActualJob);
          setSearchInProgress(false);
          return;
        }
        setSearchReturnedNoResult(true);
        setProjects([]);
        setSearchInProgress(false);
        return;
      }

      if (error) console.error(error);

      setSearchInProgress(false);
    } catch (error) {
      console.log(error);
      setSearchInProgress(false);
    }
  }, []);

  const triggerSearch = () => {
    const trimmed = localTerm? localTerm.trim() : "";
    
    if (!isValidTextAndCharLength(trimmed.replace("-",""), minSearchLength)) {
      setIsError(true);
      setErrorMessage(appConfig.Search.searchBoxMinCharErrorTXT);
      setLocalTerm(trimmed);
      return;
    }
    if (trimmed.length > 30) {
      setIsError(true);
      setErrorMessage(appConfig.Search.searchBoxMaxCharErrorTXT);
      return;
    }
    
    const stripHypenFromProjectNumber = /^[+ 0-9]{6}-[+ 0-9]{2}$/.test(trimmed) ? trimmed.replace("-",""): trimmed;
    getData(stripHypenFromProjectNumber);

  };

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;
    setLocalTerm(value);
    if (isError && value && value.length >= minSearchLength) {
      setIsError(false);
      setErrorMessage("");
    }
  };

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === "Enter") {
      triggerSearch();
    }
  };

  return (
    <div 
    // style={{ minWidth: "400px", width: "40%" }} 
    className={props.className}>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#aaa",
          backgroundColor: "#fff",
          border: "1px solid",
          borderColor: isError ? "#d32f2f" : "#aaa",
          borderRadius: "16px",
          padding: "8px",
          paddingLeft: "16px",
          height: "32px",
        }}
      >
        <InputBase
          id="searchTerm"
          name="searchterm"
          placeholder={appConfig.Search.searchBoxPlaceHolderTXT}
          value={localTerm}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          sx={{
            flex: 1,
            fontSize: "16px",
          }}
          inputProps={{
            autoComplete: "off",
            "aria-label": "Project search text input",
          }}
        />
        <IconButton
          aria-label="Submit project search"
          color="primary"
          onClick={triggerSearch}
          sx={{
            height: "28px",
            width: "24px",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "0.5px",
          }}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      {isError && (
        <Paper
          elevation={24}
          style={{
            // border: "none",
            // boxShadow: "none",
            display: "flex",
            marginTop: "4px",
            // padding: "2px",
            backgroundColor: "#fff",
            // outline: "1px solid #d32f2f",
            // borderColor: "#d32f2f",
            padding: "4px",
          }}
        >
          <WarningIcon color="error" fontSize="small" />
          <Typography
            variant="body1"
            color="error"
            sx={{ marginLeft: "5px", fontSize: 14 }}
          >
            {errorMessage}
          </Typography>
        </Paper>
      )}
    </div>
  );
};

export default SearchBox;
