import { Embed, service } from "powerbi-client";
import { EventHandler } from "../components/PowerBIEmbed";
import { Location } from "react-router-dom";

export function eventHandlers(location: Location, 
  onPageChanged: (event: service.ICustomEvent<unknown>, projectId: string | undefined) => void, 
  onReportLoaded: (event:service.ICustomEvent<unknown>) => void,
  id: string | undefined): Map<string, EventHandler> {
  console.log(`[eventHandlers], location.pathname:${location.pathname}, id:${id}`);
  
  return new Map([
    [
      "buttonClicked",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        // console.log(`[EVENT buttonClicked] id:${id}, location:${location.pathname}, currentPage:`, currentPage, event);
        console.log(`[EVENT]buttonClicked:`,event);
      },
    ],
    [
      "info",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(`[EVENT]info`, event);
      },
    ],
    [
      "commandTriggered",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(`[EVENT]commandTriggered`, event);
      },
    ],
    [
      "dataHyperlinkClicked",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(`[EVENT]dataHyperlinkClicked`, event);
      },
    ],
    [
      "selectionChanged",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(`[EVENT]selectionChanged:`,event);
      },
    ],
    [
      "renderingStarted",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(`[EVENT]renderingStarted`, event);
      },
    ],
    [
      "loaded",
      async (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log("[EVENT]loaded, [Layout onLoaded] Report load successful", event);
        onReportLoaded(event);
      },
    ],
    [
      "rendered",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log(
          `[EVENT]rendered,[Layout onRendered in ${location.pathname}] Report render successful `,
          embeddedEntity?.getId()
        );
      },
    ],
    [
      "dataSelected",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        console.log("[EVENT]dataSelected, Layout Report event:dataSelected", event);
      },
    ],
    [
      "pageChanged",
      (event: service.ICustomEvent<any>, embeddedEntity: Embed) => {
        onPageChanged(event, id);
        console.log(`[EVENT]pageChanged:`, event);
      },
    ],
    [
      "error",
      (event?: service.ICustomEvent<any>) => {
        console.error(`[EVENT]error:`,event);
        if (event) {
          if (event.detail.message === "TokenExpired") {
            window.location.reload();
          }
        }
      },
    ],
  ]) as Map<string, EventHandler>;
}
