import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Lock, CheckCircle, Warning, Cancel } from "@mui/icons-material";

export const addPadLockIfNoAccess = (
  params: GridRenderCellParams<Number | string>
) =>
  (params.row.ca) ? (
    (params.value !== null) && params.formattedValue
  ) : (
    <Lock titleAccess={"No access to financial data for company " + params.row.cc + "-" + params.row.cn} />
  );

export const addIppIcons = (params: GridRenderCellParams<Number | string>) => {
  if (!params.value) return;
  switch (params.value) {
    case "Ok":
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CheckCircle
            titleAccess="Ok"
            sx={{ color: "#24994B", textAlign: "center" }}
          />
        </div>
      );
    case "Warning":
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Warning titleAccess="Warning" sx={{ color: "#D58E10" }} />
        </div>
      );
    case "Critical":
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Cancel titleAccess="Critical" sx={{ color: "#B60007" }} />
        </div>
      );
    default:
      break;
  }
};

export const appendPercentSymbol = (
  params: GridValueFormatterParams<number>
) => {
  if (params.value == null) {
    return "";
  }
  return `${params.value}%`;
};

export const numberFormatter = new Intl.NumberFormat('en-GB');
export const monthNames = ["Jan", "Feb", "Mar", "Apr",
"May", "Jun", "Jul", "Aug",
"Sep", "Oct", "Nov", "Dec"];

