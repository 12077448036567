import * as React from "react";
import Avatar from "@mui/material/Avatar";

export interface INameAvatarProps {
  name: string;
}

export default function NameAvatar(props: INameAvatarProps) {
  const stringToColor = (name: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        height: "32px",
        width: "32px",
        fontSize: "inherit"
      },
      children:
        name.indexOf(" ") > 0
          ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
          : name[0],
    };
  };

  return <Avatar {...stringAvatar(props.name)} />;
}
