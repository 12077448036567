import axios, { AxiosRequestConfig } from "axios";
import { IEmbedConfiguration, models } from "powerbi-client";
import { reportId, workspaceId } from "../config/appConfig";
import { PCA, AquireAccessToken } from "./authenticate";
import msGraphApiService from "./ms.graph.api";
import { getLayout } from "../utils/helpers";

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

const client = axios.create({
  baseURL: "https://api.powerbi.com",
  headers,
});

class PowerBiService {
  constructor() {
    // Request interceptor for API calls
    client.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const token = await this.getToken();
        config.headers = {
          Authorization: `Bearer ${token}`,
          ...headers,
        };
        return config;
      },
      (error) => {
        console.error("Error retrieving project info.", error);
        Promise.reject(error);
      }
    );
  }

  // Power BI REST API call to refresh User Permissions in Power BI
  // Refreshes user permissions and makes sure the user permissions are fully updated
  // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
  tryRefreshUserPermissions = async (accessToken: string) => {
    try {
      const response = await client.post("/v1.0/myorg/RefreshUserPermissions", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (response.status) {
        localStorage.setItem("ApexRefreshUserPermissions", Date());
        console.log(
          `[tryRefreshUserPermissions] User permissions refreshed successfully. response.status:`,
          response.status
        );
      }

      // Too many requests in one hour will cause the API to fail
      if (response.status === 429) {
        console.log(
          "[tryRefreshUserPermissions] 429 - Permissions refresh will be available in up to an hour.",
          response
        );
      } else {
        console.log(`[tryRefreshUserPermissions] response: `, response);
      }
      return response;
    } catch (error: any) {
      console.log(
        "[tryRefreshUserPermissions] Failure in making API call.",
        error
      );
      if (error?.response?.status === 429) {
        const errorObj = {
          response: {
            data: {
              error:
                "Access to Apex Portal is not ready yet. Please try after visiting the portal after an hour. If the issue continues after an hour, please contact the support team.",
            },
          },
        };
        return Promise.reject(errorObj);
      }
      return await Promise.reject(error);
    }
  };

  getToken = async () => {
    return await AquireAccessToken(PCA, [
      "https://analysis.windows.net/powerbi/api/Report.Read.All",
      "https://analysis.windows.net/powerbi/api/Workspace.Read.All",
      "Directory.Read.All",
    ]);
  };

  getEmbedUrl = async (token: string) => {
    return client.get(`/v1.0/myorg/groups/${workspaceId}/reports/${reportId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  getGroups = (token: string) => {
    return client.get(`/v1.0/myorg/groups?$filter=id eq '${workspaceId}'`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  sleep = (ms: number) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("");
      }, ms);
    });
  };

  getNewAccessToken = async () => {
    // Code you need to add for generating new Azure AD token
    const token = await this.getToken();
    console.log("Got token", token);
    return token;
  };

  getEmbedConfiguration = async () => {
    const debugMode = localStorage.getItem("ApexDebug");
    const errorObj = {
      response: {
        data: {
          error: "",
        },
      },
    };

    try {
      const token = await this.getToken();
      const adGroupMembership = await msGraphApiService.getDirectoryMembership(
        "Apex_PBI_RO"
      );
      if (adGroupMembership?.status === 200) {
        console.log(
          `[getEmbedConfiguration] adGroupMembership:`,
          adGroupMembership?.data?.value
        );
      }
      let groups: { status: number; data: { value: Array<{ id: string }> } } =
        await this.getGroups(token);
      if (groups?.status === 200) {
        console.log("[getEmbedConfiguration] groups", groups?.data?.value);
      }
      window.apexGroups = groups?.data?.value;

      if (adGroupMembership?.data?.value?.length !== 1) {
        errorObj.response.data.error =
          "You do not have access to Apex portal. Please contact the support team to request access";
        return Promise.reject(errorObj);
      }

      if (groups?.data?.value?.length === 0) {
        const refreshPermissionResponse = await this.tryRefreshUserPermissions(
          token
        );
        if (refreshPermissionResponse.status === 200) {
          console.log(`[getEmbedConfiguration] sleeping for 5 seconds`);
          await this.sleep(5000);
          groups = await this.getGroups(token);
          console.log(
            `[getEmbedConfiguration] got groups`,
            groups?.data?.value
          );
        }
      }
      if (groups?.data?.value?.length === 1) {
        const response = await this.getEmbedUrl(token);
        console.log(`[getEmbedConfiguration] EmbedUrl`, response.data.embedUrl);

        let embedConfiguration: IEmbedConfiguration = {
          type: "report",
          tokenType: models.TokenType.Aad,
          accessToken: token,
          embedUrl: response.data.embedUrl,
          eventHooks: {
            accessTokenProvider: this.getNewAccessToken,
          },
          id: reportId,
          settings: {
            layoutType: getLayout(),
            background: models.BackgroundType.Transparent,
            panes: {
              filters: {
                visible: debugMode ? true : false,
              },
              pageNavigation: {
                visible: debugMode ? true : false,
              },
            },
          },
        };

        if (debugMode)
          console.log("EmbedConfiguration ready", embedConfiguration);

        return embedConfiguration;
      }

      errorObj.response.data.error =
        "Access to Apex Portal is not ready yet. Please try after visiting the portal after an hour. If the issue continues after an hour, please contact the support team.";
      window.apexErrorObject = errorObj;
      return Promise.reject(errorObj);
    } catch (error) {
      window.apexErrorObject = error;
      return Promise.reject(error);
    }
  };
}

export default new PowerBiService();
