import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useRecoilValue } from "recoil";
import { favouritesState } from "../State";
import { ProjectsData } from "../types/ProjectsData";

export default function NavItemSubMenu(props: { projectRelativeUrl?: string }) {
  const favourites = useRecoilValue<any>(favouritesState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };
  const menuItemOnClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        color: "#575757",
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            // width: '20ch',
            width: "332px",
            /* Arup Body 3 */
            fontFamily: "'Arial'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            /* identical to box height, or 167% */
            letterSpacing: "0.65px",
            /* arc-grey/070 */
            color: "#575757",
            padding: "6px 0 6px",
            /* arc-white/000 */
            background: "#FFFFFF",

            "& .MuiList-root": {
              p: 0,
              "& .MuiMenuItem-root": {
                fontSize: "12px",
                minHeight: "unset",
                height: "32px",
                lineHeight: "32px",
                padding: "0px 16px 0px",
              },
            },
          },
        }}
      >
        <div
          style={{
            gap: "8px",
            alignItems: "center",
            display: "inline-flex",
            padding: "0px 16px 0px",
          }}
        >
          <OpenInNewIcon sx={{ fontSize: "16px" }} />
          Open this dashboard in a favourited project:
        </div>
        {(!favourites || favourites.length == 0) && (
          <MenuItem onClick={handleClose} disabled>
            You have no favourited projects yet
          </MenuItem>
        )}
        {favourites &&
          favourites.length > 0 &&
          favourites.map((item: ProjectsData) => (
            <MenuItem disableGutters
              href={props.projectRelativeUrl ? `/project/${item.id}/${props.projectRelativeUrl}`:`/project/${item.id}`}
              target="_blank"
              component="a"
              rel="noopener noreferrer"
              key={item.id}
              // onClick={handleClose}
              onClick={menuItemOnClicked}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {item.id} {item.n}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
