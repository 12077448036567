import { useEffect, useLayoutEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";
import { ReportPage } from "../config/appConfig";
import { getFilter } from "../utils/helpers";
import { DocumentHead } from "../common/DocumentHead";
import {
  activeReportPageState,
  embedConfigurationState,
  navigateToProjectState,
  navigateToSubProjectState,
  reportLoadedState,
  reportReadyState,
  selectedProjectState,
} from "../State";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Embed, IEmbedConfiguration, Report, service } from "powerbi-client";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { PowerBIEmbed } from "../components/PowerBIEmbed";
import { eventHandlers } from "../utils/eventHandlers";
import OpenProjectTopBar from "../components/OpenProjectTopBar";

const Project = () => {
  const { id } = useParams<"id">();
  const isNarrowWidth = !useMediaQuery("(min-width:600px)");
  const [navigateToProject, setNavigateToProject] = useRecoilState<boolean>(
    navigateToProjectState
  );

  const [navigateToSubProject, setNavigateToSubProject] =
    useRecoilState<boolean>(navigateToSubProjectState);

  const [activeReportPage, setActiveReportPage] = useRecoilState<string>(
    activeReportPageState
  );

  const [reportReady, setreportReady] =
    useRecoilState<boolean>(reportReadyState);

  const setReportLoaded = useSetRecoilState<boolean>(reportLoadedState);

  const embedConfiguration = useRecoilValue<IEmbedConfiguration | undefined>(
    embedConfigurationState
  );
  const selectedProject = useRecoilValue<string>(selectedProjectState);

  let location = useLocation();
  const navigationType = useNavigationType();

  console.log(
    `[Project render ${navigationType} to ${location.pathname}] activeReportPage:${activeReportPage}, ${navigateToProject} | ${navigateToSubProject}`
  );

  const navigate = useNavigate();

  useLayoutEffect(() => {
    console.log(
      `[Project useLayoutEffect ${navigationType} to ${location.pathname}]reportReady:${reportReady}, activeReportPage:${activeReportPage}, P:${navigateToProject} | SUB:${navigateToSubProject}`
    );

    if (
      activeReportPage !== ReportPage.ProjectDetails.displayName &&
      location.pathname === `/project/${id}` &&
      navigateToProject
    ) {
      if (reportReady && window.report) {
        const init = async () => {
          setNavigateToProject(false);

          const filters = getFilter(id);
          await window.report.setFilters(filters).catch((errors) => {
            console.error(
              `[Project useLayoutEffect ${navigationType} to ${location.pathname}] Error applying filter:`,
              errors
            );
          });

          await window.report.setPage(ReportPage.ProjectDetails.name);
          console.log(
            `[Project useLayoutEffect ${navigationType} to ${location.pathname}] Switched to Project Details page.`
          );
        };

        init();
      } else {
        console.log(
          `[Project useLayoutEffect] Unexpected: window.report and reportReady doesn't match. window.report is ${
            window.report ? "available" : "not available"
          }, reportReady:${reportReady}`
        );
      }
    }
    return () => {
      console.log(`[Project useLayoutEffect] return block`);
    };
  });
  useEffect(() => {
    console.log(
      `[Project useEffect ${navigationType} to ${location.pathname}]reportReady:${reportReady}, activeReportPage:${activeReportPage}, P:${navigateToProject} | SUB:${navigateToSubProject}`
    );

    if (
      (activeReportPage === ReportPage.Details.displayName ||
        activeReportPage === ReportPage.FinancialReport.displayName ||
        activeReportPage === ReportPage.JobFinancialReport.displayName ||
        activeReportPage === ReportPage.Expenses.displayName ||
        // activeReportPage === ReportPage.CompareExpenses.displayName ||
        activeReportPage === ReportPage.Invoices.displayName ||
        activeReportPage === ReportPage.Timesheets.displayName ||
        activeReportPage === ReportPage.StaffResourcing.displayName ||
        activeReportPage === ReportPage.HoursAndCosts.displayName ||
        activeReportPage ===
          ReportPage.SiteVisitTrainingCompliance.displayName ||
        activeReportPage === ReportPage.UnexpectedTimeBookings.displayName || activeReportPage === ReportPage.ProjectTeamMembers.displayName ||           
        activeReportPage === ReportPage.Opportunities.displayName || activeReportPage === ReportPage.KeyDates.displayName) &&
      location.pathname === `/project/${id}` &&
      navigateToSubProject &&
      !navigateToProject
    ) {
      const subPageUrl = activeReportPage.toLowerCase().replace(/\s+/g, "-");
      console.log(
        `[Project useEffect${navigationType} to ${location.pathname}] Navigating to ${subPageUrl}`
      );
      navigate(subPageUrl);
      return;
    }

    return () => {
      console.log(`[Project useEffect] unmounting.`);
    };
  }, [
    location.pathname,
    reportReady,
    activeReportPage,
    navigateToProject,
    navigateToSubProject,
  ]);

  const onPageChanged = (
    event: service.ICustomEvent<unknown>,
    projectId: string | undefined
  ) => {
    const eventDetail = event.detail as any;
    const newPage = eventDetail.newPage.displayName as any;
    console.log(
      `[Layout onPageChanged ${navigationType} to ${location.pathname}] Report Event: PageChanged to ${newPage}, activeReportPage:${activeReportPage}, id:${id}, selectedProject:${selectedProject}, projectId:${projectId}`
    );

    if (newPage !== activeReportPage) {
      console.log(
        `[Layout onPageChanged] activeReportPage was ${activeReportPage}. Will be updated to ${newPage}`
      );
      if (
        newPage === ReportPage.Details.displayName ||
        newPage === ReportPage.FinancialReport.displayName ||
        newPage === ReportPage.JobFinancialReport.displayName ||
        newPage === ReportPage.Expenses.displayName ||
        // newPage === ReportPage.CompareExpenses.displayName ||
        newPage === ReportPage.Invoices.displayName ||
        newPage === ReportPage.Timesheets.displayName ||
        newPage === ReportPage.StaffResourcing.displayName ||
        newPage === ReportPage.HoursAndCosts.displayName ||
        newPage === ReportPage.SiteVisitTrainingCompliance.displayName ||
        newPage === ReportPage.UnexpectedTimeBookings.displayName ||
        newPage === ReportPage.ProjectTeamMembers.displayName ||
        newPage === ReportPage.Opportunities.displayName ||
        newPage === ReportPage.KeyDates.displayName
      ) {
        setNavigateToSubProject(true);
        setNavigateToProject(false);
      }

      setActiveReportPage(newPage);
    }
  };

  const onReportLoaded = (
    event: service.ICustomEvent<unknown>,
  ) => {
    setReportLoaded(true);
    console.log(`[EVENTHANDLER]onReportLoaded] Report loaded. Enabling sidebar navigation links`);
  };

  const calculateHeight = (): number => {
    switch (activeReportPage) {
      case ReportPage.ProjectDetails.displayName:
        return (isNarrowWidth ? 2630 : 1000);
      case ReportPage.SiteVisitTrainingCompliance.displayName:
      case ReportPage.UnexpectedTimeBookings.displayName:
      case ReportPage.ProjectTeamMembers.displayName:
      case ReportPage.KeyDates.displayName:
        return 830;
      case ReportPage.FinancialReport.displayName:
      case "Project financial report":
        return 1650;
      case ReportPage.JobFinancialReport.displayName:
        return 1090;
      case ReportPage.Opportunities.displayName:
        return 1240;
      case ReportPage.Expenses.displayName:
        return 1590;
      case ReportPage.Details.displayName:
          return 1030;
      case ReportPage.Invoices.displayName:
        return 1720;
      case ReportPage.HoursAndCosts.displayName:
        return 1070;
      default:
        return 1000;
    }
  };

  return (
    <>
      {location.pathname === `/project/${id}` && <DocumentHead title={id || "Project page"} projectNumber={id || ""} subPageTitle="" />}
      {id && <OpenProjectTopBar projectId={id} isAMS={navigateToProject} pageName={activeReportPage}/>}
      <Outlet />
      {!embedConfiguration && (
        <>
          <CircularProgress />
          <span>Loading...</span>
        </>
      )}
      {embedConfiguration && (
        <PowerBIEmbed
          embedConfig={embedConfiguration}
          eventHandlers={eventHandlers(location, onPageChanged, onReportLoaded, id)}
          cssClassName={"report-style-class"}
          styleProp={{
            height: `${calculateHeight() + 16}px`, //16px to compensate possible horizontal scrollbar's height for small widths
          }}
          getEmbeddedComponent={(embedObject: Embed) => {
            console.log(
              `[Layout render getEmbeddedComponent] Embedded object of type "${embedObject.embedtype}" received`,
              embedObject
            );

            window.report = embedObject as Report;
            setreportReady(true);
          }}
        />
      )}
    </>
  );
};

export default Project;
