export const appConfig = {
  ENVIRONMENT: process.env.REACT_APP_ENV,
  Search: {
    minSearchLength:3,
    searchBoxPlaceHolderTXT: "Search for a number, name, PD, PM or group...",
    searchBoxMinCharErrorTXT: "Use 3 or more alphanumeric characters to search",
    searchBoxMaxCharErrorTXT: "Use 30 or fewer characters to search",
  }
};

export enum ReportId {
  ApexSandpit = "7ec24565-9501-4616-a67a-fbbee241ceaf",
  // ApexDev = "3bdac968-968a-4d1f-b3e0-9523bae37282",
  ApexDev = "fbf96571-59a8-44eb-9d91-3f6772ec3176",
  ApexTest = "e78bdd64-8b72-4c77-b882-53887afb6b85",
  ApexProd = "8065c8a8-a354-46c2-b7f7-b0722a55cedb",
}

export const ReportPage = {
  ProjectDetails: { displayName: "ProjectDetails", name:"ReportSection6b76f5caccb377714bc9"},
  Details: { displayName:"Details", name: "ReportSectiona508a534475ed7eb3bbb"},
  FinancialReport: { displayName: "Financial report", name:"ReportSectionc957ab1c1e93a6636c48"},
  JobFinancialReport: { displayName: "Job financial report", name: "ReportSectiond4bdb97cfe52ba8885a5"},
  Expenses: { displayName: "Expenses", name:"ReportSection487c9ba972e9c70e9965"},
  // CompareExpenses: { displayName: "Compare expenses", name:"ReportSection850077397de4ade2b969"},
  Invoices: { displayName: "Invoices", name:"ReportSection65b6bb8c8ebe7bd19dae"},
  Timesheets: { displayName: "Timesheets", name:"ReportSectionbe195b9169c9f08d0039"},
  StaffResourcing: { displayName: "Staff resourcing", name:"ReportSection568adac5260e905eb38b"},
  HoursAndCosts: { displayName: "Hours and costs", name:"ReportSection83c98754a016c1ece9b1"},
  SiteVisitTrainingCompliance: { displayName:"Site visit training compliance", name: "ReportSectionded8324b90c9212de20d"},
  UnexpectedTimeBookings: { displayName:"Unexpected time bookings", name: "ReportSection3f659e22b725830c67d7"},
  Opportunities: { displayName:"Opportunities", name: "ReportSectionfa683521e7e2b121ca4a"},
  KeyDates: { displayName:"Key dates", name: "ReportSectionaed4a49de4cb2df96f1c"},
  ProjectTeamMembers: { displayName:"Project team members", name: "ReportSectiondfec672accb066a4aecf"},
} as const;

export type IReportPage = typeof ReportPage[keyof typeof ReportPage];

export const ReportPageMap = new Map([
[
  "LandingPage", 
  { displayName: "LandingPage", name:"ReportSectiond12a7ca3c5220033028a"}
],
]);

/* eslint-disable @typescript-eslint/no-inferrable-types */

// Scope of AAD app. Use the below configuration to use all the permissions provided in the AAD app through Azure portal.
// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
export const scopes: string[] = [
  "https://analysis.windows.net/powerbi/api/Report.Read.All",
  "https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All",
];

export const workspaceId: string = "8c609b0f-f6b1-47f2-b2a3-0f79ce669fc2";

const getReportId = () => {
  const hostname = window.location.hostname;

  if (hostname.indexOf("sandpit") >= 0 ) {
    return ReportId.ApexSandpit;
  }

  if (hostname.indexOf("localhost") >= 0) {
    return ReportId.ApexSandpit;
  }

  if (hostname.indexOf("dev") >= 0) {
    return ReportId.ApexDev;
  }

  if (hostname.indexOf("test") >= 0) {
    return ReportId.ApexTest;
  }

  return ReportId.ApexProd;
};

export const reportId = getReportId();

const getPostHogKey = () => {
  const prodPOSTHOG_API_KEY = "phc_dUUQtcHYS9qXH7z1tlFzc4PxyHvRbnZrRCs7M03Ib3u";
  const nonProdPOSTHOG_API_KEY = "phc_IBsygcpHvneD8hMEg2xZHIdTcyuaKVn6LReX7WWsOy6";
  const hostname = window.location.hostname;

  if (hostname.indexOf("apexportal.arup.com") >= 0 ) {
    return prodPOSTHOG_API_KEY;
  }

  return nonProdPOSTHOG_API_KEY;
};

export const postHogKey = getPostHogKey();