import { Card, CardContent, Typography, Divider } from "@mui/material";
import { FavoriteButton } from "../common/FavoriteButton";
import SubMenu from "../common/SubMenu";

export const DataGridCard = (params: {
  value: any;
  row: any;
  visibleColumns: Array<any>;
  hasFocus: boolean;
}) => {
  const lines = params.visibleColumns
    .filter((i) => i.hideable)
    .map((i) => {
      const renderedCell = i.renderCell
        ? i.renderCell({
          value: params.row[i.field],
          row: params.row,
          i,
        })
        : undefined;

      const valueFormatted = i.valueFormatter
        ? i.valueFormatter({
          value: params.row[i.field],
          row: params.row,
          i,
        })
        : params.row[i.field];

      return (
        <div
          key={i.headerName}
          className="card-content"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <div className="card-content-label">{i.headerName}</div>
          <div className="card-content-text">
            {renderedCell || valueFormatted}
          </div>
        </div>
      );
    });

  return (
    <Card>
      <CardContent>
        <div
          className="card-title"
          style={{
            display: "flex",
            alignItems: "flex-start",
            padding: "8px",
            gap: "8px",
            // height: "100px",
          }}
        >
          <div
            className="card-title-contextual"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px",
              gap: "8px",
              width: "40px",
              height: "40px",
            }}
          >
            <FavoriteButton
              isFavorite={params.row.fav}
              project={params.row.id}
              tabIndexValue={params.hasFocus ? 0 : -1}
              iconTabIndexValue={-1} />
          </div>
          <div
            className="card-title-frame"
            style={{
              // height: "84px",
              flex: "none",
              flexGrow: "1",
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              padding: "0px",
              gap: "8px",
            }}
          >
            <a href={`/project/${params.row.id}`}>{params.row.id}</a>
            <div
              style={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  alignItems: "flex-start",
                  flex: "none",
                  order: "0",
                  flexGrow: "1",
                  width: "151px",
                }}
              >
                {params.row.n}
              </Typography>
              <SubMenu
                projectNumber={params.row.id || ""}
                apUrl={params.row.apUrl || ""}
                oppGuid={params.row.oppGuid || ""} 
                startDt={params.row.startDt}
                />
            </div>
          </div>
        </div>
        <Divider />
        <div className="card-content">
          <div className="card-content-label">Project director</div>
          <div className="card-content-text">{params.row.pd}</div>
          <div className="card-content-label">Project manager</div>
          <div className="card-content-text">{params.row.pm}</div>
        </div>
        {lines.length > 0 && <Divider />}
        {lines}
        <div className="card-footer">
          <a href={`/project/${params.row.id}`}>Open project</a>
        </div>
      </CardContent>
    </Card>
  );
};
