import * as React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems :"center",
  textAlign: "center",
  height: "100%",
  padding: "32px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "30px",
}));

export function CustomErrorOverlay(text: string, errorMessage?: string) {
  return (
    <StyledGridOverlay>
      <Box sx={{ p: 3, mt: 1 }}>
        <Typography sx={{ mb: 1 }}>{text}</Typography>

        {errorMessage && (
          <>
            Technical details: <div>{errorMessage}</div>
          </>
        )}
      </Box>
    </StyledGridOverlay>
  );
}

export function CustomNoRowsOverlay(text: JSX.Element): JSX.Element {
  return <StyledGridOverlay>{text}</StyledGridOverlay>;
}

export function CustomLinearProgressWithText(text: JSX.Element): JSX.Element {
  return <div><LinearProgress></LinearProgress><br></br><StyledGridOverlay>{text}</StyledGridOverlay></div>
}